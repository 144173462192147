import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import BgImg from "assets/ShopHeaderBg.png";

export namespace S {
  export const ShopHeader = styled(Box)(({ theme }: any) => ({
    minHeight: "22.25rem",
    height: "100%",
    background: "#DBE8F1",
    // display: "flex",
    // flexWrap: "wrap",
    width: "100%",
    padding:"1rem 0 0 0",
    marginBottom: "2.5rem",
    // backgroundImage: `url(${BgImg})`,
   
    [theme.breakpoints.down("sm")]: {
      minHeight: "28.375rem",
    },
  }));
  export const ShopSubHeader = styled(Box)(({ theme }: any) => ({
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    margin:"auto",
    maxWidth:"1400px",
    "@media (max-width: 1200px)": {
      gridTemplateColumns: "1fr",
      marginBottom: "1rem",
    },
  }));
  export const HeaderTextContainer = styled(Box)(({ theme }: any) => ({
    padding: "3.8125rem 3.25rem 61px 6.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    // width: "70%",
    // "@media (max-width: 1225px)": {
    //  order:1
    // },
    "@media (max-width: 1200px)": {
      alignItems: "center",
      padding: "5.8125rem 1rem 5.8125rem 1rem",
    },
  }));
  export const BannerImgContainer = styled(Box)(({ theme }: any) => ({
    position: "relative",
    "@media (max-width: 1200px)": {
      display: "none",
    },
  }));
  export const HeaderBannerText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "3.3125rem",
    lineHeight: "3.75rem",
    "@media (max-width: 1200px)": {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.3125rem",
      lineHeight: "2.75rem",
    },
  }));
  export const AppStoreContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxWidth: "500px",
    gap: "1rem",
    "@media (max-width: 1200px)": {
      justifyContent: "center",
      marginTop: "1rem",
      width: "100%",
    },
  }));
  export const HowItWorksContainer = styled(Box)(({ theme }: any) => ({
    minHeight: "637px",
    height: "100%",
    background: theme.palette.common.white,
    width: "100%",
  }));
  export const TestimonailsContainer = styled(Box)(({ theme }: any) => ({
    minHeight: "580px",
    height: "100%",
    width: "100%",
  }));
  export const TestimonialHeader = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "42px",
    textAlign: "center",
    boxSizing: "border-box",
    padding: "2.3125rem 0",
  }));
  export const TestimonialContainer = styled(Box)(({ theme }: any) => ({
    background: theme.palette.secondary.main,
    minHeight: "457px",
    width: "85%",
    margin: "auto",
    maxWidth: "1400px",
    position: "relative",
    display: "flex",
    "@media (max-width: 1400px)": {
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "5rem",
      marginBottom: "5rem",
      boxSizing: "border-box",
    },
  }));
  export const TestimonialRound = styled(Box)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    width: "148px",
    height: "148px",
    borderRadius: "50%",
    position: "absolute",
    top: "-4rem",
    left: "2.8125rem",
    display: "grid",
    placeContent: "center",
    "@media (max-width: 900px)": {
      height: "90px",
      width: "90px",
      top: "-2.6rem",
      left: "1.8125rem",
    },
    "@media (max-width: 450px)": {
      display: "none",
    },
  }));
  export const TestimonialImage = styled.img`
    height: 66.6px;
    width: 89.6px;
    @media (max-width: 900px) {
      height: 20px;
      width: 20px;
    }
  `;
  export const TestimonialLeftStaticContainer = styled(Box)(
    ({ theme }: any) => ({
      height: "100%",
      width: "35%",
      margin: "auto 0",
      padding: "33px 0 0 33px",
      "@media (max-width: 1400px)": {
        width: "70%",
        textAlign: "center",
        padding: "4rem 1rem 0 1rem",
      },
      "@media (max-width:600px)": {
        padding: "6rem 1rem 0 1rem",
      },
    })
  );
  export const TestimonialRightStaticContainer = styled(Box)(
    ({ theme }: any) => ({
      height: "80%",
      margin: "auto",
      width: "65%",
      position: "absolute",
      right: "-62px",
      top: "35px",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: "30px",
      "@media (max-width: 1400px)": {
        justifyContent: "space-around",
        position: "relative",
        width: "90%",
        right: "0px",
        margin: "0",
      },
      "@media (max-width: 1100px)": {
        justifyContent: "center",
        width: "80%",
        right: "0px",
        margin: "0",
      },
    })
  );
  export const TestimonialMainText = styled(Box)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "26px",
    lineHeight: "34px",
  }));
  export const TestimonialSubText = styled(TestimonialMainText)(
    ({ theme }: any) => ({
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "23px",
      color: theme.palette.primary.main,
    })
  );
  export const TestimonialCountryText = styled(TestimonialMainText)(
    ({ theme }: any) => ({
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      fontStyle: "italic",
      // color: theme.palette.primary.main,
    })
  );

  export const HowItWorksMainContainer = styled(Box)(({ theme }: any) => ({
    width: "100%",
    maxWidth: "1400px",
    margin: "auto",
    padding: "2.1875rem 80px",
  }));

  export const HowItWorksMainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: "2.625rem",
    // textAlign: "left",
  }));
  export const HowItWorksText = styled(HowItWorksMainText)(
    ({ theme }: any) => ({
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "1.9375rem",
    })
  );
  export const HowItWorksCardContainer = styled(Box)(({ theme }: any) => ({
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "2rem",
    padding: "66px",
  }));
  export const HowItWorksCard = styled(Box)(({ theme }: any) => ({
    width: "335px",
    height: "392px",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  }));

  export const ShopBar = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1rem",
    width: "100%",
    padding: "0 4rem 0 3rem",
    boxSizing: "border-box",
  }));

  export const HeaderBgText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "2rem",
    lineHeight: "2.4375rem",
  }));

  export const PopularBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      flex: "1 1 0",
    },
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      gap: 10,
      flexGrow: 0,
    },
  }));

  export const PopularText = styled(Typography)(({ theme }: any) => ({
    fontSize: "1.5rem",
    fontWeight: "500",
    marginRight: "1rem",
  }));

  export const PopularCard = styled(Box)(({ theme }: any) => ({
    height: "52px",
    width: "100%",
    minWidth: "162px",
    maxWidth: "400px",
    display: "grid",
    placeItems: "center",
    background: "#ffffff",
    boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: " 4px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));

  export const PopularCountryContainer = styled(Box)(({ theme }: any) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    gap: "2rem",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
  }));

  export const ShopContainer = styled(Container)(({ theme }: any) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "3rem 0 ",
    flexWrap: "wrap",
    gap: "2rem",
    width: "100%",
    maxWidth: "1400px",
    minHeight: "calc(100vh - 21rem)",
  }));

  export const PageBtn = styled(Button)(({ theme }: any) => ({
    background: "#66717B",
    borderRadius: "8px",
    minWidth: "44px !important",
    height: "44px",
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    border: "none",
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
      boxShadow: "none",
      color: theme.palette.primary.contrastText,
    },
  }));

  export const PageSideBtn = styled(PageBtn)(({ theme }: any) => ({
    background: theme.palette.primary.light,
    borderRadius: "8px",
    color: "black",
  }));

  export const MoreContainer = styled(Container)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "2rem",
    padding: "3rem",
    minHeight: "calc(100vh - 21rem)",
  }));

  export const MoreDetailCard = styled(Card)(({ theme }: any) => ({
    width: "100%",
    maxWidth: "51rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.primary.contrastText,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)",
    borderRadius: "8px",
  }));

  export const MoreCardHeader = styled(CardContent)(({ theme }: any) => ({
    width: "88%",
    padding: "1.5rem 3.5rem",
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px 8px 0px 0px",
  }));

  export const ContentBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  }));

  export const HeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "2.25rem",
    lineHeight: "2.9375rem",
    color: theme.palette.primary.contrastText,
  }));

  export const Main = styled(Box)(({ theme }: any) => ({
    margin: "2rem",
    width: "88%",
  }));

  export const MainContent = styled(Box)(({ theme }: any) => ({
    gap: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  }));

  export const MainContentBox = styled(ContentBox)(({ theme }: any) => ({
    alignItems: "start",
    flexDirection: "column",
  }));

  export const MainSubContentBox = styled(MainContentBox)(({ theme }: any) => ({
    gap: "1.5rem",
  }));

  export const MainContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "1.4375rem",
    color: theme.palette.text.primary,
  }));

  export const MainContentSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.9375rem",
    color: theme.palette.text.primary,
    textAlign: "right",
  }));

  export const SubContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.3125rem",
    color: theme.palette.primary.main,
    display: "flex",
  }));

  export const SubContentSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "0.9375rem",
    lineHeight: "1.3125rem",
    color: "#66717B",
  }));

  export const CheckBox = styled.input(({ theme }: any) => ({
    width: "21px",
    height: "21px",
    border: "1px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "0.25rem",
  }));

  export const ConditionText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "0.9375rem",
    lineHeight: "1.25rem",
    color: theme.palette.text.primary,
  }));

  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    color: theme.palette.common.white,
    minWidth: "17rem !important",
    height: "50px",
    boxShadow: "none",
    border: "none",
    fontWeight: 700,
    padding: "0rem 0.625rem",
    fontSize: "1.125rem",
    lineHeight: "1.4375rem",
    textTransform: "capitalize",
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    "&:disabled": {
      background: "#C5C5C5",
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "12rem !important",
    },
    [theme.breakpoints.only("xs")]: {
      minWidth: "12rem !important",
    },
  }));

  export const BackBtn = styled(ConfirmBtn)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    minWidth: "18rem !important",
    backgroundColor: "rgba(69, 181, 73, 0.1)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }));

  export const ActiveBtn = styled(ConfirmBtn)(({ theme }: any) => ({
    minWidth: "18rem !important",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: "#2f592f",
    },
  }));

  export const GntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "2.625rem",
    lineHeight: "3.4375rem",
    color: theme.palette.text.primary,
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      fontSize: "2rem",
    },
  }));

  export const PurchaseContent = styled(ContentBox)(({ theme }: any) => ({
    gap: "2rem",
    flexDirection: "column",
  }));

  export const PurchaseContentBox = styled(ContentBox)(({ theme }: any) => ({
    background: "#f3f5f7",
    height: "87px",
    width: "100%",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.9375rem",
    color: "#000000",
    textAlign: "center",
  }));

  export const RedeemMain = styled(ContentBox)(({ theme }: any) => ({
    gap: "2rem",
    flexWrap: "wrap",
  }));

  export const RedeemMainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.75rem",
    letterSpacing: "-0.02em",
    color: theme.palette.text.primary,
  }));

  export const RedeemContentText = styled(RedeemMainText)(({ theme }: any) => ({
    lineHeight: "21px",
    color: "#66717B",
  }));

  export const QrCodeContent = styled(Box)(({ theme }: any) => ({
    background: theme.palette.primary.contrastText,
    padding: "0.8rem",
    border: "5px solid #CED6E0",
    borderRadius: "8px",
  }));

  export const CustomShopContainer = styled(Box)(({ theme }: any) => ({
    // background: theme.palette.common.white,
    height: "100%",
    width: "90%",
    display: "grid",
    gridTemplateColumns: "repeat(1,minmax(0,1fr))",
    // gap: "2rem",
    "@media (min-width: 576px)": {
      gridTemplateColumns: "repeat(2,minmax(0,1fr))",
    },
    "@media (min-width: 1024px)": {
      gridTemplateColumns: "repeat(3,minmax(0,1fr))",
    },
  }));
  export const CountryCard = styled(Box)(({ theme }: any) => ({
    background: theme.palette.common.white,
    boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "21px",
    lineHeight: "27px",
    textAlign: "right",
    color: "#0B213E",
  }));
  export const CardContainer = styled(Box)(({ theme }: any) => ({
    // height: '100%',
    left: 0,
    // width: '20rem',
    boxSizing: "border-box",
  }));
  export const DataPlansDataContainer = styled(Grid)(({ theme }: any) => ({
    display: "flex",
    allignItems: "center",
    width: "93%",
    flexWrap: "wrap",
    padding: "0 1rem",
  }));
  export const PlansDataContainer = styled(DataPlansDataContainer)(
    ({ theme }: any) => ({
      display: "flex",
      allignItems: "center",
      width: "100%",
      flexWrap: "wrap",
      // padding: "2rem 0",
    })
  );
  export const NoPacksContainer = styled(Box)(({ theme }: any) => ({
    display: "grid",
    placeContent: "center",
  }));
}
