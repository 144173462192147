import styled from "@emotion/styled";
import { Box, Card, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import ReactCountryFlag from "react-country-flag";

import WorldIcon from "assets/icons/WorldIcon.svg";
import TagsIcon from "assets/icons/tags.svg";
import TowerIcon from "assets/icons/TowerIcon.svg";
import CalendarIcon from "assets/icons/calendar.svg";

interface Props {
  data: any;
  countryData?: any;
  country?: any;
  color?: any;
  location?: string;
  flag?: any;
  handleOpen?: () => void;
  apiInstance?: any;
  mainData?: any;
}

const PrimaryCard: FC<Props> = ({
  mainData,
  data,
  country,
  color,
  flag,
  location,
  handleOpen,
  countryData,
  apiInstance,
}) => {
  const [expiredState, setExpiredState] = useState<boolean>(true);

  let packSize = data.dataLimitInBytes / (1024 * 1024) / 1024;

  useEffect(() => {
    const expiredField = apiInstance?.filter(
      (instance: any) => parseInt(instance.instanceId) === mainData.id
    );
    if (expiredField?.length > 0) {
      setExpiredState(expiredField[0]?.isExpired);
    }
  }, []);

  const handleModal = () => {
    handleOpen?.();
    let manipulatedData = {
      ...data,
      country: country,
      color: color,
      flagCountryCode: flag,
    };
    // modalDataHandler({
    //   data: manipulatedData,
    //   modalCountryData: countryData,
    // });
  };

  return (
    <CustomCard>
      <CardHeader
        theme={`linear-gradient(113.74deg, ${
          color ? color?.start : "#F8D0D4"
        } 9.6%, ${color ? color?.end : "#FDF0F2"} 91.34%)`}
      >
        <ContentBox>
          <HeaderText>{packSize} GB</HeaderText>
          <SubText>4G/LTE</SubText>
        </ContentBox>
        <ContentBox
          sx={{ display: "flex", alignItems: "flex-end", minWidth: "150px" }}
        >
          <SubText
            sx={{
              maxWidth: "120px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {country}
          </SubText>
          {flag === "Global" ? (
            <img
              src={WorldIcon}
              alt="currency img"
              style={{ width: "28px", height: "28px", paddingTop: "10px" }}
            />
          ) : (
            <ReactCountryFlag
              style={{ paddingTop: "10px" }}
              countryCode={flag}
            />
          )}
        </ContentBox>
      </CardHeader>
      <Box sx={{ display: "flex", flexDirection: "column", width: "85%" }}>
        <MainContent>
          <MainSubText>
            <img
              style={{ paddingRight: "10px" }}
              src={CalendarIcon}
              alt="CalendarIcon"
            />
            Active Period
          </MainSubText>
          <MainGntText>30 Days</MainGntText>
        </MainContent>
        <MainContent>
          <MainSubText>
            <img
              style={{ paddingRight: "10px" }}
              src={TagsIcon}
              alt="TagsIcon"
            />
            GIANT Rewards
          </MainSubText>
          <MainGntText>{data?.bonusRewards}</MainGntText>
        </MainContent>
        <MainContent>
          <MainSubText>
            <img
              style={{ paddingRight: "10px" }}
              src={TowerIcon}
              alt="TowerIcon"
            />
            Carrier
          </MainSubText>
          <MainGntText
            style={{
              maxWidth: "130px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {country === "Global" || country === "Europe"
              ? "Multiple"
              : data?.carrier}
          </MainGntText>
        </MainContent>
        <MainText sx={{ paddingTop: "0.5rem" }}>
          ${data?.priceInWifiToken?.toFixed(2)}{" "}
        </MainText>
      </Box>
      {location !== "manage" && (
        <PrimaryButton
          style={{ height: "50px", width: "90%" }}
          onClick={() => {
            handleModal();
            (window as any).analytics.track(
              "Clicked on Preview and Buy button",
              {
                country: country,
                dataLimitInBytes: data.dataLimitInBytes,
                price: data?.priceInWifiToken,
                ServiceType: data?.serviceType,
                vendor: data?.vendor,
                rewards: data?.bonusRewards,
              }
            );
          }}
        >
          Preview and Buy
        </PrimaryButton>
      )}
    </CustomCard>
  );
};

const CustomCard = styled(Card)(({ theme }: any) => ({
  gap: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: theme.palette.primary.contrastText,
  boxShadow:
    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)",
  borderRadius: "8px",
  padding: "0 0 1rem 0",
  width: "387px",
  [theme.breakpoints.up("md")]: {
    maxHeight: 350,
  },
  [theme.breakpoints.down("sm")]: {
    width: "350px",
  },
}));

const CardHeader = styled(Box)(({ theme }: any) => ({
  width: "100%",
  padding: "1.5rem 0.5rem",
  display: "flex",
  gap: "2rem",
  justifyContent: "space-around",
  alignItems: "center",
  flexWrap: "wrap",
  background: `${theme}`,
  borderRadius: "8px 8px 0px 0px",
  "@media (max-width: 400px)": {
    gap: "0.5rem",
  },
}));

const ContentBox = styled(Box)(({ theme }: any) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
}));
const MainContent = styled(Box)(({ theme }: any) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1rem",
  width: "100%",
  flexWrap: "wrap",
}));
const HeaderText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "42px",
  color: theme.palette.common.black,
}));

const SubText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "23px",
  paddingTop: "0.625rem",
  color: "#0B213E",
}));

const MainText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: "2rem",
  lineHeight: "2.625rem",
  color: theme.palette.text.primary,
}));

const MainSubText = styled(SubText)(({ theme }: any) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: "1.125rem",
  lineHeight: "1.4375rem",
}));

const MainGntText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: "1.125rem",
  lineHeight: "1.4375rem",
  textAlign: "right",
  color: theme.palette.text.primary,
}));

export default PrimaryCard;
