import axios from "axios";
import { HttpClient } from "./httpClient";

let httpClient = new HttpClient();

// export const getCountiresDataForScroll = (params, country) => {
//   return httpClient.get(
//     // country !== null ? `countries?_limit=3&_start=${params}&country=${country}` : `countries?_limit=3&_start=${params}`,
//     country !== ""
//       ? `countries?_limit=${params}&country=${country}`
//       : `countries?_limit=${params}`
//   );
// };

// export const getAccountByAddress = (address) => {
//   return httpClient.get(`accounts?address=${address}`);
// };

// export const getCurrentAccounts = () => {
//   return httpClient.get("accounts");
// };

// export const createAccount = (params) => {
//   return httpClient.post("accounts", params);
// };
// export const createInstance = (params) => {
//   return httpClient.post("instances", params);
// };
// export const updateAccount = (params, id) => {
//   return httpClient.put(`accounts/${id}`, params);
// };

export const getCountries = () => {
  return httpClient.get("api//lte/destinations");
};

export const getPopularPacks = () => {
  return httpClient.get("api/lte/popularPacks");
};

export const getPacks = (params) => {
  return httpClient.post("api/lte/esim/packs/v1", params);
};

export const getUrls = (params) => {
  return httpClient.post("api/checkout/shop/purchase", params);
};

export const getQR = (params) => {
  return httpClient.post("api/checkout/shop/esim/activate", params);
};

export const getActivateStatus = (params) => {
  return httpClient.post("api/checkout/eSim/status", params);
};

export const getGiftData = (params) => {
  return httpClient.post("api/checkout/shop/esim/giftPack/activate", params);
};

export const getActivationState = (params) => {
  return httpClient.post("api/checkout/eSim/activationStatus", params);
};

export const getDataFromOrderId = (params) => {
  return httpClient.post("api/checkout/eSim/getOrderInfo", params);
}

export const headers = {
  "content-type": "application/json",
  // Authorization: '<token>',
};

export const fetchQueries = async (graphqlQuery) => {
  return await axios({
    url: `${process.env.REACT_APP_BACKEND_URL}graphql`,
    method: "POST",
    headers: headers,
    data: JSON.stringify(graphqlQuery),
  });
};

export const generateGiftCode = async (params) => {
  return await httpClient.get(
    `generateGiftCode?owner=${params.owner}&instanceId=${params.instanceId}`
  );
};

export const validateCode = async (params) => {
  return await httpClient.get(`validateCode?code=${params}`);
};
