import React, { FC } from "react";
import Content from "./content";
import Header from "./header";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouters from "routes/AppRouters";
import { Footer } from "./footer";

const Layout: FC = () => {
  return (
    <div className="main-layout">
      <Router>
        <Header />
        <Content>
          <AppRouters />
        </Content>
        <Footer />
      </Router>
    </div>
  );
};

export default Layout;
