import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import PrimaryButton from "components/buttons/PrimaryButton";
import PrimaryCard from "components/cards/PrimaryCard";
import { FC, Fragment, useEffect, useState } from "react";
import { getCountries, getPacks } from "utils/constants/config";
// import { fetchCountryCount } from 'utils/constants/queries'
import ReactCountryFlag from "react-country-flag";
import { S } from "./shop.style";
import WorldIcon from "assets/icons/WorldIcon.svg";
import PurchaseModal from "components/modals/PurchaseModal";

interface Props {
  country: string;
  count: number;
}

const PacksContainer: FC<Props> = ({ country, count }) => {
  const match2 = useMediaQuery("(max-width:1360px)");
  // const { isLoading, data: countryList } = useQuery('getCountryNameForPacks', () =>
  //   fetchQueries(fetchCountries(variables)),
  // )

  const [dataId, setDataId] = useState<any>({ id: "", index: "" });
  const [cardData, setCardData] = useState<any>();
  const [showModal, setShowModal] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [filteredState, setFilteredState] = useState<any>(false);
  const [modalData, setModalData] = useState<any>({});
  const [countryData, setCountryData] = useState<any>([]);
  const [currentCountryData, setCurrentCountryData] = useState<any>([]);
  const [countryCount, setCountryCount] = useState<any>(0);
  const variables = {};
  const twoGrid = useMediaQuery("(min-width:576px)");
  const threeGrid = useMediaQuery("(min-width:1024px)");

  useEffect(() => {
    getCountries().then((countryList: any) => {
      setIsLoading(true);
      // if (country === "") {
      //   let manipulatedData = countryList?.data?.country.slice(0, 12);
      //   !filteredState
      //     ? setCountryData(manipulatedData)
      //     : setCountryData(countryList?.data?.country);
      //   setIsLoading(false);
      // } else {
      if (country !== "") {
        let filteredData = countryList?.data?.country.filter(
          (data: any) => data.country === country
        );
        setCountryData(filteredData);
        setIsLoading(false);
      } else {
        setCountryData([]);
      }
      //   }
    });
  }, [country, filteredState]);

  // const {
  //   data: countryList,
  //   isLoading,
  //   fetchNextPage,
  //   isFetchingNextPage,
  // } = useInfiniteQuery(
  //   ['countriesData', country],
  //   ({ pageParam = 12 }) => {
  //     return getCountiresDataForScroll(pageParam, country)
  //   },
  //   {
  //     getNextPageParam: (_lastPage: any, pages: any) => {
  //       return 0
  //     },
  //   },
  // )

  // useEffect(() => {
  //   if (countryList?.pages !== undefined) {
  //     if (countryList?.pages.length === 1) {
  //       const filteredCountryList = countryList?.pages[0].filter((country: any) => country.packs.length > 0)
  //       setCountryData(filteredCountryList)
  //     } else if (countryList?.pages.length === 2) {
  //       const fullFilteredCountryList = countryList?.pages[1].filter((country: any) => country.packs.length > 0)
  //       setCountryData(fullFilteredCountryList)
  //     }
  //   }
  // }, [countryList])

  // useEffect(() => {
  //   fetchQueries(fetchCountryCount).then((value: any) => {
  //     let data = value.data.data.countriesConnection.aggregate
  //     setCountryCount(data.totalCount)
  //   })
  // }, [])

  useEffect(() => {
    if (!cardData) {
      setIsLoading(true);
    }
  }, [cardData]);

  const getDataForCards = (data: any) => {
    setCurrentCountryData(data);
    if (data !== "") {
      getPacks({
        isGlobal: false,
        isRegional: false,
        isCountry: true,
        code: data.destination,
      }).then((res: any) => {
        setCardData(res);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (countryData.length === 1) {
      if (country === "") {
        setDataId({ id: "", index: "" });
      } else {
        setDataId({ id: countryData[0]?.country, index: 0 });
        getDataForCards(countryData[0]);
      }
    }
  }, [countryData, country]);

  const handleClose = () => {
    setShowModal(false);
  };

  // if (isLoading) {
  //   return <CircularProgress />;
  // }

  // Note:  to show the selected country's package below the grid row of the specific country on all resolutions
  const getGridRow = (): any => {
    if (dataId.index !== "") {
      if (twoGrid && !threeGrid)
        return parseInt(((dataId.index + 3) / 2).toFixed());
      if (threeGrid) return parseInt(((dataId.index + 2) / 3).toFixed()) + 1;
      return dataId.index + 2;
    }
  };

  // Note:  To check how many grids needs to be spaned to get the packs of the selected country in full width
  const getColumnCount = (): any => {
    if (twoGrid && !threeGrid) return 2;
    if (threeGrid) return 3;
    return 1;
  };

  return (
    <>
      <S.CustomShopContainer>
        {/* {countryData.length > 0 &&
          countryData.map((data: any, index: number) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <S.CountryCard
                  sx={
                    data.country === dataId.id
                      ? {
                          border: "1px solid rgb(69, 181, 73)",
                          color: "rgb(69, 181, 73)",
                          fontWeight: "500",
                        }
                      : {}
                  }
                  onClick={() => {
                    // setCardData(data);
                    getDataForCards(data);
                    setDataId((prevState: any) => {
                      if (prevState.id === data.country) {
                        return { id: "", index: "" };
                      }
                      return { id: data.country, index: index };
                    });
                  }}
                >
                  {data.country} &nbsp;
                  {data.flagCountryCode === "Global" ? (
                    <img
                      src={WorldIcon}
                      alt="currency img"
                      style={{ width: "28px", height: "28px" }}
                    />
                  ) : (
                    <ReactCountryFlag countryCode={data.flagCountryCode} />
                  )}
                </S.CountryCard>
              </div>
            );
          })} */}

        {dataId?.id && (
          <S.CardContainer
            sx={{
              gridRowStart: getGridRow(),
              gridColumn: `span ${getColumnCount()}/span ${getColumnCount()}`,
            }}
          >
            {cardData?.data?.length > 0 ? (
              <S.PlansDataContainer
                item
                sx={{
                  justifyContent: `${match2 ? "center" : "space-between"}`,
                  gap: `${match2 ? "2rem" : "0"}`,
                }}
              >
                {cardData?.data?.map((pack: any, index: any) => {
                  return (
                    <PrimaryCard
                      key={index}
                      data={pack}
                      country={currentCountryData.country}
                      // countryData={cardData}
                      color={currentCountryData.countryColor}
                      flag={currentCountryData.flagCountryCode}
                      location="shop"
                      handleOpen={() => {
                        setShowModal(true);
                        setModalData({
                          ...pack,
                          flag: currentCountryData.flagCountryCode,
                          country: currentCountryData.country,
                          iso: currentCountryData.destination,
                          color: currentCountryData.countryColor,
                        });
                      }}
                    />
                  );
                })}
              </S.PlansDataContainer>
            ) : (
              <S.NoPacksContainer>
                <CircularProgress />
              </S.NoPacksContainer>
            )}
          </S.CardContainer>
        )}
      </S.CustomShopContainer>

      {/* {countryData?.length <= 12 && country === "" && count > 0 && (
        <PrimaryButton
          style={{ height: "50px", width: "386px" }}
          onClick={() => {
            setFilteredState(true);
          }}
        >
          Show {count - 12} countries
        </PrimaryButton>
      )} */}
      {filteredState === true && isLoading === true && <CircularProgress />}
      {/* {countryData.length === 0 && (
        <Box sx={{ width: "100%", display: "grid", placeContent: "center" }}>
          No Packs Found
        </Box>
      )} */}

      <PurchaseModal
        show={showModal}
        handleCloseModal={handleClose}
        modalData={modalData}
      />
    </>
  );
};

export default PacksContainer;
