import { FC } from "react";
import { S } from "./installationguide.style";
import two from "assets/installation/bg02.svg";
import Android2 from "assets/installation/Android2.svg";
import Android3 from "assets/installation/Android3.svg";
import bg01 from "assets/installation/Android1.svg";
import smallRound from "assets/installation/roundSmall.svg";
import { Box } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const Android: FC = () => {
  const match = useMediaQuery("(max-width:900px)");

  return (
    <S.AndroidMainContainer>
      <S.AndroidLeftContainer>
        <img src={two} style={{ width: match ? "80%" : "unset" }} alt="" />
        <img src={Android2} style={{ width: match ? "80%" : "unset" }} alt="" />
        <img src={Android3} style={{ width: match ? "80%" : "unset" }} alt="" />
        <img
          src={bg01}
          style={{
            position: "absolute",
            left: "0",
            zIndex: "-1",
            top: match ? "4rem" : "15rem",
            height: match ? "100px" : "unset",
          }}
          alt=""
        />
        <img
          src={smallRound}
          style={{
            position: "absolute",
            left: "4.8125rem",
            zIndex: "-1",
            top: match ? "18rem" : "34rem",
            height: match ? "100px" : "unset",
          }}
          alt=""
        />
        <img
          src={smallRound}
          style={{
            position: "absolute",
            right: "4rem",
            zIndex: "-1",
            top: match ? "35rem" : "53rem",
            height: match ? "100px" : "unset",
          }}
          alt=""
        />
      </S.AndroidLeftContainer>
      <S.AndroidRightContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound sx={{ position: "relative" }}>
            1
            <Box
              sx={{
                position: "absolute",
                left: "1rem",
                width: "2px",
                height: "100vh",
                zIndex: "-1",
                background: "#45B549",
              }}
            ></Box>
          </S.GreenRound>
          <S.AndroidInstallationSubtext>
            Open the eSIM purchase confirmation email you <br />
            received and find the QR Code
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>2</S.GreenRound>
          <S.AndroidInstallationSubtext>
            Go to Settings on your device
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>3</S.GreenRound>
          <S.AndroidInstallationSubtext>
            Tap on Network & Internet
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>4</S.GreenRound>
          <S.AndroidInstallationSubtext>
            Tap the Add icon next to Mobile Network
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>5</S.GreenRound>
          <S.AndroidInstallationSubtext>
            Tap Next when asked "Don't have a SIM card?"
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>6</S.GreenRound>
          <S.AndroidInstallationSubtext>
            You can now scan your QR code
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>7</S.GreenRound>
          <S.AndroidInstallationSubtext>
            If necessary,go to Network & Internet and tap on <br />
            Mobile network to set the correct network <br />
            preferences listed in the Additional info which is <br />
            at the bottom-right of the selected data <br />
            package page.
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>8</S.GreenRound>
          <S.AndroidInstallationSubtext>
            Turn on eSIM under Mobile Network
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound>9</S.GreenRound>
          <S.AndroidInstallationSubtext>
            Enable Mobile Data
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
        <S.AndroidInstallationSubContainer>
          <S.GreenRound sx={{ position: "relative" }}>
            10
            <Box
              sx={{
                position: "absolute",
                left: "1rem",
                width: "2px",
                height: "100vh",
                bottom: "10%",
                zIndex: "-1",
                background: "#45B549",
              }}
            ></Box>
          </S.GreenRound>
          <S.AndroidInstallationSubtext>
            Set up an APN (access point name) on your <br />
            device if required. If the APN settings are <br />
            required for your eSIM, you can fint the APN <br />
            details in the eSIM installation details
          </S.AndroidInstallationSubtext>
        </S.AndroidInstallationSubContainer>
      </S.AndroidRightContainer>
    </S.AndroidMainContainer>
  );
};

export default Android;
