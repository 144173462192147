import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { FC, Fragment, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import Select, { components, ControlProps } from "react-select";
import PurchaseModal from "components/modals/PurchaseModal";

import WorldLogo from "assets/icons/World.svg";
import DownArrow from "assets/icons/DownArrow.svg";
import PlayStore from "assets/PlayStore.svg";
import AppStore from "assets/AppStore.svg";
import BannerImg from "assets/BannerImg.svg";
import BannerImg1 from "assets/BannerImg1.svg";
import WhiteSemicolon from "assets/WhiteSemicolon.svg";
import mainBanner from "assets/mainBanner.svg";

import {
  HOW_IT_WORKS_DATA,
  POPULAR_COUNTRIES,
  TESTIMONIAL_CARD_DATA,
} from "utils/constants";
import { S } from "./shop.style";
import {
  getCountries,
  getPacks,
  getPopularPacks,
} from "utils/constants/config";
import PrimaryCard from "components/cards/PrimaryCard";
import PacksContainer from "./PacksContainer";
import { useParams, useSearchParams } from "react-router-dom";
import { HOW_IT_WORKS_DATATYPE, TESTIMONIAL_CARD_DATATYPE } from "utils/types";
import TestimonialCard from "components/cards/TestimonialCard";

const Shop: FC = () => {
  const [popularCountry, setPopularCountry] = useState<string>("");
  const [popularCountryFlag, setPopularCountryFlag] = useState<any>({
    flagCountryCode: "US",
    country: "",
  });
  const [selectCountry, setSelectCountry] = useState<any>({
    value: "",
    label: "",
    flagCountryCode: "",
  });
  const [countryOptions, setCountryOption] = useState<any>([]);
  const [dummyCountryData, setDummyCountryData] = useState<any>([]);
  const [popularCountryData, setPopularCountryData] = useState<any>([]);
  const [isPopular, setIsPopular] = useState<any>(false);
  const [defaultCardData, setDefaultCardData] = useState<any>([]);
  const [countryCount, setCountryCount] = useState<number>(0);
  const [showModal, setShowModal] = useState<any>(false);
  const [modalData, setModalData] = useState<any>({});
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  // console.log("id", id);
  const handleClose = () => {
    setShowModal(false);
  };

  const match2 = useMediaQuery("(max-width:1360px)");
  const filterValue = selectCountry?.value ? selectCountry?.value : "";

  useEffect(() => {
    if (searchParams.get("orderId") !== null) setShowModal(true);
  }, [searchParams.get("orderId")]);

  useEffect(() => {
    if (id) setShowModal(true);
  }, [id]);

  useEffect(() => {
    getCountries().then((countryList: any) => {
      const countryDataList = countryList?.data?.country?.map((data: any) => {
        return {
          value: data.country,
          label: data.country,
          flagCountryCode: data?.flagCountryCode,
        };
      });
      const popularCountries = countryList?.data?.country?.filter(
        (data: any) => data.isMostPopular === true
      );
      // if (popularCountries.length === 0 ){setIsPopular(true);
      setPopularCountryData(popularCountries);
      // setIsPopular(false);
      setCountryOption(countryDataList);
      setCountryCount(countryDataList.length);
      getPopularPacks().then((res) => setDefaultCardData(res?.data));
    });
  }, []);
  useEffect(() => {
    getPacks({
      isGlobal: false,
      isRegional: false,
      isCountry: true,
      code: popularCountry,
    }).then((res: any) => setDummyCountryData(res?.data));
  }, [popularCountry]);

  useEffect(() => {
    if (filterValue !== "") {
      setPopularCountry("");
      setPopularCountryFlag({
        flagCountryCode: "US",
        country: "",
      });
    }
  }, [filterValue]);

  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <>
        <components.Control {...props}>
          {selectCountry === null ||
          selectCountry?.flagCountryCode === "" ||
          selectCountry?.flagCountryCode === "Global" ? (
            <img
              src={WorldLogo}
              alt="WorldLogo"
              style={{ width: "21px", height: "28px" }}
            />
          ) : (
            <ReactCountryFlag countryCode={selectCountry?.flagCountryCode} />
          )}
          {children} <img src={DownArrow} alt="arrow" />
        </components.Control>
      </>
    );
  };

  return (
    <>
      <S.ShopHeader>
        {/* <S.HeaderBgText>
          Global cellular data roaming in 118 countries
          <br /> connect to earn free data
        </S.HeaderBgText> */}
        <S.ShopSubHeader>
          <S.HeaderTextContainer>
            <S.HeaderBannerText>
              Buy or gift mobile data
              <br /> Earn crypto-backed rewards
            </S.HeaderBannerText>
            {/* <S.AppStoreContainer>
            <img
              src={PlayStore}
              onClick={() =>
                window.open("https://wific0in.app.link/SCPal1gVbrb")
              }
              style={{ cursor: "pointer" }}
              alt="playstore img"
            />
            <img
              src={AppStore}
              onClick={() =>
                window.open("https://wific0in.app.link/SCPal1gVbrb")
              }
              style={{ cursor: "pointer" }}
              alt="appstore img"
            />
            zxciuvhjsaeuiysdfm m
          </S.AppStoreContainer> */}
          </S.HeaderTextContainer>
          <S.BannerImgContainer>
            <img src={mainBanner} alt="" />
          </S.BannerImgContainer>
        </S.ShopSubHeader>
      </S.ShopHeader>
      <S.HowItWorksMainText sx={{ textAlign: "center" }}>
        Shop
      </S.HowItWorksMainText>
      <S.HowItWorksText sx={{ textAlign: "center" }}>
        SIM-less, on demand mobile data in 118 countries
      </S.HowItWorksText>
      <S.ShopContainer maxWidth={false}>
        <S.ShopBar>
          <Select
            options={countryOptions}
            value={selectCountry?.value && selectCountry}
            onChange={setSelectCountry}
            isSearchable
            components={{ Control }}
            placeholder={"Choose your country"}
            isClearable
            // closeMenuOnSelect={false}
            styles={{
              placeholder: (base) => ({
                ...base,
                opacity: "0.8",
                fontWeight: 500,
                fontSize: "1.4rem",
                lineHeight: "31px",
                color: "#000000",
              }),
              control: (base) => ({
                ...base,
                background: "#e2e7ee",
                border: "none",
                borderRadius: "0.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontWeight: 500,
                fontSize: "1.5rem",
                lineHeight: "1.9375rem",
                color: "#000000",
                cursor: "pointer",
                padding: "0.5rem 1.5rem",
                width: "25rem",
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: "none",
              }),
              // clearIndicator: (base) => ({
              //   ...base,
              //   cursor: 'pointer',
              //   color: 'black',
              // }),
              dropdownIndicator: (base) => ({
                ...base,
                display: "none",
              }),
              container: (base) => ({
                ...base,
                maxWidth: "26rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 1rem",
              }),
              menu: (base) => ({
                ...base,
                maxWidth: "23rem",
                "@media(max-width:600px)": {
                  maxWidth: "20rem",
                },
              }),
            }}
          />
          <S.PopularBox>
            <S.PopularText>Popular: </S.PopularText>
            <S.PopularCountryContainer>
              {popularCountryData.map((country: any, index: any) => {
                return (
                  <S.PopularCard
                    sx={
                      country.destination === popularCountry
                        ? {
                            border: "1px solid rgb(69, 181, 73)",
                            color: "rgb(69, 181, 73)",
                            fontWeight: "500",
                          }
                        : {}
                    }
                    key={index}
                    onClick={() => {
                      setSelectCountry({ value: "", label: "" });
                      setPopularCountry((prev: any) => {
                        if (prev === country.destination) {
                          return "";
                        } else {
                          return country.destination;
                        }
                      });
                      setPopularCountryFlag((prev: any) => {
                        if (prev === country.flagCountryCode) {
                          return {
                            flagCountryCode: "US",
                            country: prev.country,
                          };
                        } else {
                          return {
                            flagCountryCode: country.flagCountryCode,
                            country: country.country,
                          };
                        }
                      });
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "21px",
                        lineHeight: "27px",
                        textAlign: "right",
                        color: "#0B213E",
                      }}
                    >
                      {country.country} &nbsp;
                      <ReactCountryFlag countryCode={country.flagCountryCode} />
                    </Typography>
                  </S.PopularCard>
                );
              })}
            </S.PopularCountryContainer>
          </S.PopularBox>
        </S.ShopBar>
        {filterValue === "" && (
          <S.DataPlansDataContainer
            item
            sx={{
              justifyContent: `${match2 ? "center" : "space-between"}`,
              gap: `${match2 ? "2rem" : "0"}`,
            }}
          >
            {popularCountry === "" ? (
              <>
                {defaultCardData?.map((val: any, index: any) => {
                  let country = val.country;
                  let countryColor = val.countryColor;
                  let countryFlag = val.flagCountryCode;
                  return (
                    <Fragment key={index}>
                      <PrimaryCard
                        data={val}
                        // key={pack.id}
                        // data={pack}
                        // countryData={val}
                        country={country}
                        color={countryColor}
                        flag={countryFlag}
                        // location="shop"
                        handleOpen={() => {
                          setShowModal(true);
                          setModalData({
                            ...val,
                            flag: countryFlag,
                            country: country,
                            iso: val.destination,
                            color: countryColor,
                          });
                        }}
                      />
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <>
                {dummyCountryData?.map((val: any, index: any) => {
                  let country = popularCountryFlag.country;
                  let countryColor = val.countryColor;
                  let countryFlag = popularCountryFlag.flagCountryCode;
                  return (
                    <Fragment key={index}>
                      <PrimaryCard
                        data={val}
                        // key={pack.id}
                        // data={pack}
                        // countryData={val}
                        country={country}
                        color={countryColor}
                        flag={countryFlag}
                        // location="shop"
                        handleOpen={() => {
                          setShowModal(true);
                          setModalData({
                            ...val,
                            flag: popularCountryFlag.flagCountryCode,
                            country: country,
                            iso: popularCountry,
                            color: countryColor,
                          });
                        }}
                      />
                    </Fragment>
                  );
                })}
              </>
            )}
          </S.DataPlansDataContainer>
        )}
        {/* <button onClick={() => setShowModal(true)}>Open Modal</button> */}
        <PacksContainer country={filterValue} count={countryCount} />
        <PurchaseModal
          show={showModal}
          id={id}
          handleCloseModal={handleClose}
          modalData={modalData}
        />
      </S.ShopContainer>
      <S.HowItWorksContainer>
        <S.HowItWorksMainContainer>
          <S.HowItWorksMainText sx={{ textAlign: "center" }}>
            How it works
          </S.HowItWorksMainText>
          <S.HowItWorksText sx={{ textAlign: "center" }}>
            Connect to earn in three easy steps
          </S.HowItWorksText>
          <S.HowItWorksCardContainer>
            {HOW_IT_WORKS_DATA.map((data: HOW_IT_WORKS_DATATYPE) => {
              return (
                <S.HowItWorksCard key={data.id}>
                  <img src={data.img} alt="" />
                  <S.HowItWorksText
                    sx={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ __html: data.text }}
                  ></S.HowItWorksText>
                </S.HowItWorksCard>
              );
            })}
          </S.HowItWorksCardContainer>
        </S.HowItWorksMainContainer>
      </S.HowItWorksContainer>
      <S.TestimonailsContainer>
        <S.TestimonialHeader>Testimonials</S.TestimonialHeader>
        <S.TestimonialContainer>
          <S.TestimonialRound>
            <S.TestimonialImage src={WhiteSemicolon} alt="" />
          </S.TestimonialRound>
          <S.TestimonialLeftStaticContainer>
            <S.TestimonialMainText sx={{ marginBottom: "14px" }}>
              A must-have for travelers. Works in 118 countries. Only pay for
              what you need.
              <br />
              Super generous rewards.
            </S.TestimonialMainText>
            <S.TestimonialSubText>Kathy W.</S.TestimonialSubText>
            <S.TestimonialCountryText
              sx={{ paddingTop: "0.625rem", color: "#3C4075" }}
            >
              Australia
            </S.TestimonialCountryText>
          </S.TestimonialLeftStaticContainer>
          <S.TestimonialRightStaticContainer>
            {TESTIMONIAL_CARD_DATA.map((data: TESTIMONIAL_CARD_DATATYPE) => {
              return <TestimonialCard data={data} key={data.id} />;
            })}
          </S.TestimonialRightStaticContainer>
        </S.TestimonialContainer>
      </S.TestimonailsContainer>
    </>
  );
};

export default Shop;
