import { FC } from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

interface Props {
  style: {
    height: string;
    width: string;
    alignSelf?: string;
    order?: string;
  };
  onClick?: (e?: any) => void;
  children: any;
  disabled?:any;
}

const PrimaryButton: FC<Props> = ({ style, children, onClick, disabled}) => {
  return (
    <CustomButton style={style} onClick={onClick} disabled={disabled}>
      {children}
    </CustomButton>
  );
};

const CustomButton = styled(Button)(({ theme }: any) => ({
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
  textTransform: "none",
  fontWeight: 700,
  fontSize: "1.25rem",
  borderRadius: "0.5rem",
  "&:hover": {
    background: theme.palette.primary.main,
    opacity: 0.75,
  },
}));

export default PrimaryButton;
