import { useState } from "react";
import { INSTALLATION_CHIP_DATA_ANDROID } from "utils/constants";
import { S } from "./installationguide.style";
import InstallationBannerImg from "assets/InstallationBannerImg.svg";

import one from "assets/installation/01.svg";
import two from "assets/installation/02.svg";
import three from "assets/installation/03.svg";
import four from "assets/installation/04.svg";
import five from "assets/installation/05.svg";
import six from "assets/installation/06.svg";
import seven from "assets/installation/07.svg";
import eight from "assets/installation/08.svg";
import nine from "assets/installation/09.svg";
import bgone from "assets/installation/bg01.svg";
import bgtwo from "assets/installation/bg02.svg";
import bgthree from "assets/installation/bg03.svg";
import bgfour from "assets/installation/bg04.svg";
import bgfive from "assets/installation/bg05.svg";
import bgsix from "assets/installation/bg06.svg";
import bgseven from "assets/installation/bg07.svg";
import bgeight from "assets/installation/bg08.svg";
import bgnine from "assets/installation/bg09.svg";
import smallRound from "assets/installation/roundSmall.svg";
import BigRound from "assets/installation/roundBig.svg";
import WarningIcon from "assets/WarningIcon.svg";
import BannerImg from "assets/InstallationBg.svg";
import PlayStore from "assets/PlayStore.svg";
import AppStore from "assets/AppStore.svg";
import { Box } from "@mui/system";
import Android from "./Android";
import { useMediaQuery } from "@mui/material";

const InstallationGuide = () => {
  const [activeTab, setActiveTab] = useState(0);
  const match = useMediaQuery("(max-width:760px)");

  return (
    <S.InstallationMainContainer>
      <Box
        sx={{ position: "relative", display: "flex", flexDirection: "column" }}
      >
        <S.InstallationHeader
          sx={{ background: activeTab === 0 ? "#DBE8F1" : "transparent" }}
        >
          <S.MainHeaderText>
            Before you fly or at your destination <br />
            Step-by-step installation guide
          </S.MainHeaderText>
          <img
            src={InstallationBannerImg}
            alt="InstallationBannerImg"
            style={{
              maxWidth: "916px",
              width: "100%",
            }}
          />
        </S.InstallationHeader>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <S.InstallationToggleContainer>
            <div
              style={{
                position: "relative",
              }}
            >
              <S.InstallationToggleAndroid
                disableRipple={true}
                onClick={() => setActiveTab(0)}
                sx={{
                  background: activeTab === 0 ? "#45B549" : "#D9D9D9",
                  color: activeTab === 0 ? "#FFFFFF" : "#0B213E",
                  zIndex: activeTab === 0 ? "2" : "unset",
                  "&:hover": {
                    background: activeTab === 0 ? "#45B549" : "#D9D9D9",
                    color: activeTab === 0 ? "#FFFFFF" : "#0B213E",
                  },
                }}
              >
                iOS
              </S.InstallationToggleAndroid>
              <S.InstallationToggleIos
                disableRipple={true}
                onClick={() => setActiveTab(1)}
                sx={{
                  background: activeTab === 1 ? "#45B549" : "#D9D9D9",
                  color: activeTab === 1 ? "#FFFFFF" : "#0B213E",
                  "&:hover": {
                    background: activeTab === 1 ? "#45B549" : "#D9D9D9",
                    color: activeTab === 1 ? "#FFFFFF" : "#0B213E",
                  },
                }}
              >
                Android
              </S.InstallationToggleIos>
            </div>
          </S.InstallationToggleContainer>
        </Box>
      </Box>
      <S.InstallationSubHeader>
        <S.InstallationSubText sx={{ textAlign: "center", fontWeight: 700 }}>
          Supported Devices
        </S.InstallationSubText>
        <S.InstallationChipContainer>
          {activeTab === 1 ? (
            INSTALLATION_CHIP_DATA_ANDROID.map((data: any) => {
              return <S.InstallationChip>{data?.title}</S.InstallationChip>;
            })
          ) : (
            <>
              <S.InstallationSubText sx={{ textAlign: "center" }}>
                iPhone XS, iPhone XS Max, iPhone XR with or newer with iOS 12.1
                or later
              </S.InstallationSubText>
              <S.InstallationSubText sx={{ textAlign: "center" }}>
                iPad Air (3rd Generation or later), iPad Mini (5th Generation or
                later) with iOS 12.1 or later
              </S.InstallationSubText>
            </>
          )}
        </S.InstallationChipContainer>
        <S.ImportantNoteText>
          <span>
            {" "}
            <img src={WarningIcon} alt="" />
            &nbsp;
          </span>{" "}
          <b>Important Note:</b> For successfull installation, you MUST have a
          stable WiFi connection when activating an eSIM.{" "}
        </S.ImportantNoteText>
      </S.InstallationSubHeader>
      {activeTab === 0 ? (
        <S.IosMainContainer>
          <S.IosSubContainer>
            <S.LeftContainer sx={{ display: "grid", placeContent: "center" }}>
              <img src={bgone} alt="" style={{ width: "100%" }} />
              <img
                src={one}
                alt=""
                style={{ position: "absolute", zIndex: "-1", top: "9.5rem" }}
              />
            </S.LeftContainer>
            <S.RightContainer sx={{ display: "flex", padding: "0 101px 0 0" }}>
              <Box>
                <S.InstallationRound>1</S.InstallationRound>
              </Box>
              <S.InstallationSubText>
                <S.InstallationHeadText>
                  Check your iOS device is network unlocked
                </S.InstallationHeadText>
                <br />
                Go to Settings &#62; General &#62; About &#62; Carrier Lock{" "}
                <br />
                <br />
                If you see No SIM restrictions then your phone is unlocked.
                Otherwise read these instructions to unlock your phone from your
                carrier.
                <br />
                <br /> If you can't find Carrier Lock on the About screen of
                your device, please try updating your iOS version and check
                again. If that doesn't work, check out one of the other methods
                detailed in this{" "}
                <a
                  href="https://support.apple.com/en-us/HT201328"
                  style={{ color: "#45B549" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  article.
                </a>
              </S.InstallationSubText>
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer
              sx={{
                display: "flex",
                padding: "0 0 0 113px",
                order: match ? 1 : 0,
              }}
            >
              <S.InstallationSubText
                sx={{
                  textAlign: match ? "left" : "right",
                  order: match ? 1 : 0,
                }}
              >
                <S.InstallationHeadText
                  sx={{ textAlign: match ? "left" : "right" }}
                >
                  Open your email on a different device
                </S.InstallationHeadText>
                <br />
                On a different device (any another phone, tablet, or computer),
                open the email you received from GIANT Connect after purchasing
                the eSIM.
                <br />
                <br /> Be sure you can see the QR code on the screen.
              </S.InstallationSubText>
              <Box sx={{ order: match ? 0 : 1 }}>
                <S.InstallationRound>2</S.InstallationRound>
              </Box>
            </S.LeftContainer>
            <S.RightContainer
              sx={{
                display: "grid",
                placeContent: "center",
                order: match ? 0 : 1,
              }}
            >
              <img src={bgtwo} alt="" style={{ width: "100%" }} />
              <img
                src={two}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-2.5rem",
                  right: "0",
                }}
              />
              <img
                src={smallRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "6rem",
                  left: "5.0625rem",
                }}
              />
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer sx={{ display: "grid", placeContent: "center" }}>
              <img src={bgthree} alt="" style={{ width: "100%" }} />
              <img
                src={three}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-2rem",
                  left: "0",
                }}
              />
              <img
                src={smallRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "0.7rem",
                  right: "2.75rem",
                }}
              />
            </S.LeftContainer>
            <S.RightContainer sx={{ display: "flex", padding: "0 101px 0 0" }}>
              <Box>
                <S.InstallationRound>3</S.InstallationRound>
              </Box>
              <S.InstallationSubText>
                <S.InstallationHeadText>
                  Scan the QR Code with your iOS device
                </S.InstallationHeadText>
                <br />
                Using the Camera on your iOS device, scan the QR code in the
                email. <br />
                <br /> Tap the cellular Plan prompt below the QR code to be
                taken to the install Cellular Plan screen.
              </S.InstallationSubText>
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer
              sx={{
                display: "flex",
                padding: "0 0 0 71px",
                order: match ? 1 : 0,
              }}
            >
              <S.InstallationSubText
                sx={{
                  textAlign: match ? "left" : "right",
                  order: match ? 1 : 0,
                }}
              >
                <S.InstallationHeadText
                  sx={{ textAlign: match ? "left" : "right" }}
                >
                  Give your new cellular plan and give it a label
                </S.InstallationHeadText>
                <br />
                Select your new cellular plan and give it a label, for example
                "Travelling" if you plan to use this plan when you're traveling.
              </S.InstallationSubText>
              <Box sx={{ order: match ? 0 : 1 }}>
                <S.InstallationRound>4</S.InstallationRound>
              </Box>
            </S.LeftContainer>
            <S.RightContainer
              sx={{
                display: "grid",
                placeContent: "center",
                order: match ? 0 : 1,
              }}
            >
              <img src={bgfour} alt="" style={{ width: "100%" }} />
              <img
                src={four}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-3rem",
                  right: "0",
                }}
              />
              <img
                src={smallRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "5rem",
                  left: "0.75rem",
                }}
              />
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer sx={{ display: "grid", placeContent: "center" }}>
              <img src={bgfive} alt="" style={{ width: "100%" }} />
              <img
                src={five}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-4rem",
                  left: "0",
                }}
              />
              <img
                src={smallRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "5rem",
                  right: "0.75rem",
                }}
              />
            </S.LeftContainer>
            <S.RightContainer sx={{ display: "flex", padding: "0 101px 0 0" }}>
              <Box>
                <S.InstallationRound>5</S.InstallationRound>
              </Box>
              <S.InstallationSubText>
                <S.InstallationHeadText>
                  Set the Default Line as "Primary"
                </S.InstallationHeadText>
                <br />
                Using the Camera on your iOS device, scan the QR Code in the
                email. <br />
                <br /> Tap the cellular Plan prompt below the QR code to be
                taken to the install Cellular plan screen.
              </S.InstallationSubText>
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer
              sx={{
                display: "flex",
                padding: "0 0 0 106px",
                order: match ? 1 : 0,
              }}
            >
              <S.InstallationSubText
                sx={{
                  textAlign: match ? "left" : "right",
                  order: match ? 1 : 0,
                }}
              >
                <S.InstallationHeadText
                  sx={{ textAlign: match ? "left" : "right" }}
                >
                  Select which cellular plan to use for iMessage, Facetime and
                  Apple ID.
                </S.InstallationHeadText>
                <br />
                Keep this as Primary while you are in your home country. <br />
                <br /> Toggle it to Secondary later when traveling to countries
                where you would prefer eSIM coverage over roaming coverage from
                your primary carrier.
              </S.InstallationSubText>
              <Box sx={{ order: match ? 0 : 1 }}>
                <S.InstallationRound>6</S.InstallationRound>
              </Box>
            </S.LeftContainer>
            <S.RightContainer
              sx={{
                display: "grid",
                placeContent: "center",
                order: match ? 0 : 1,
              }}
            >
              <img src={bgsix} alt="" style={{ width: "100%" }} />
              <img
                src={six}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-8rem",
                  right: "0",
                }}
              />
              <img
                src={smallRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "1rem",
                  left: "0.75rem",
                }}
              />
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer sx={{ display: "grid", placeContent: "center" }}>
              <img src={bgseven} alt="" style={{ width: "100%" }} />
              <img
                src={seven}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-4rem",
                  left: "0",
                }}
              />
              <img
                src={BigRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "-1rem",
                  right: "0.75rem",
                }}
              />
            </S.LeftContainer>
            <S.RightContainer sx={{ display: "flex", padding: "0 101px 0 0" }}>
              <Box>
                <S.InstallationRound>7</S.InstallationRound>
              </Box>
              <S.InstallationSubText>
                <S.InstallationHeadText>
                  Similarly, for cellular data, choose Primary for now then
                  switch to Secondary while Travelling.
                </S.InstallationHeadText>
                <br />
                You may also select "Allow Cellular Data Switching" to allow
                your device to switch between networks depending on coverage.
                <br />
                <br />
                You can change your selection later in Settings &#62; Cellular
                &#62; Cellular Data.
              </S.InstallationSubText>
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer
              sx={{
                display: "flex",
                padding: "0 0 0 101px",
                order: match ? 1 : 0,
              }}
            >
              <S.InstallationSubText
                sx={{
                  textAlign: match ? "left" : "right",
                  order: match ? 1 : 0,
                }}
              >
                <S.InstallationHeadText
                  sx={{ textAlign: match ? "left" : "right" }}
                >
                  Choose to update contacts' preferred line later
                </S.InstallationHeadText>
                <br />
                This is so that calling contacts will always use the primary
                cellular network. Click Done.
              </S.InstallationSubText>
              <Box sx={{ order: match ? 0 : 1 }}>
                <S.InstallationRound>8</S.InstallationRound>
              </Box>
            </S.LeftContainer>
            <S.RightContainer
              sx={{
                display: "grid",
                placeContent: "center",
                order: match ? 0 : 1,
              }}
            >
              <img src={bgeight} alt="" style={{ width: "100%" }} />
              <img
                src={eight}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-4rem",
                  right: "0",
                }}
              />
              <img
                src={smallRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "1.5rem",
                  left: "0.75rem",
                }}
              />
            </S.RightContainer>
          </S.IosSubContainer>
          <S.IosSubContainer>
            <S.LeftContainer sx={{ display: "grid", placeContent: "center" }}>
              <img src={bgnine} alt="" style={{ width: "100%" }} />
              <img
                src={nine}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  top: "-6rem",
                  left: "0",
                }}
              />
              <img
                src={BigRound}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  bottom: "-6rem",
                  right: "0rem",
                }}
              />
            </S.LeftContainer>
            <S.RightContainer sx={{ display: "flex", padding: "0 101px 0 0" }}>
              <Box>
                <S.InstallationRound>9</S.InstallationRound>
              </Box>
              <S.InstallationSubText>
                <S.InstallationHeadText>
                  On clicking done, you will be taken to the main Cellular
                  settings screen where you can access the Cellular Data screen{" "}
                </S.InstallationHeadText>
                <br />
                Here you can toggle between your Primary and Secondary data
                plans at any time.
              </S.InstallationSubText>
            </S.RightContainer>
          </S.IosSubContainer>
        </S.IosMainContainer>
      ) : (
        <Android />
      )}
      <S.InstallationSubFooterContainer>
        <S.InstallationSubFooterTextContainer sx={{ order: match ? 1 : 0 }}>
          <S.InstallationSubFooterMainText>
            Ready to try eSIMs and <br />
            change the way you stay connected? <br />
          </S.InstallationSubFooterMainText>
          <S.InstallationSubFooterSubText>
            Download the GIANTConnect app to purchase, <br />
            manage, and top up your eSIMs anytime, anywhere!
          </S.InstallationSubFooterSubText>
          <S.AppstoreContainer>
            <img
              src={PlayStore}
              onClick={() =>
                window.open("https://wific0in.app.link/SCPal1gVbrb")
              }
              style={{ cursor: "pointer" }}
              alt="playstore img"
            />
            <img
              src={AppStore}
              onClick={() =>
                window.open("https://wific0in.app.link/SCPal1gVbrb")
              }
              style={{ cursor: "pointer" }}
              alt="appstore img"
            />
          </S.AppstoreContainer>
        </S.InstallationSubFooterTextContainer>
        <img
          src={BannerImg}
          alt=""
          style={{
            width: "100%",
            order: match ? 0 : 1,
          }}
        />
      </S.InstallationSubFooterContainer>
    </S.InstallationMainContainer>
  );
};

export default InstallationGuide;
