import { FC, useEffect, useState } from "react";
import { S } from "./modal.styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "assets/icons/CloseIcon.svg";
import {
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import StripeLogo from "assets/icons/StripeLogo.svg";
import DeactiveIcon from "assets/icons/Deactive.svg";
import ActiveIcon from "assets/icons/Active.svg";
import WarningIcon from "assets/icons/Group 37254.svg";
import ActiveImg from "assets/g10.svg";
import QRImg from "assets/QrImg.svg";
import SendGiftImg from "assets/Group 37222.svg";
import ShareImg from "assets/ShareIllustration.svg";
import ShareIcon from "assets/icons/shareIcon.svg";
import WorldIcon from "assets/icons/WorldIcon.svg";
import linkIcon from "assets/icons/linkIcon.svg";
import copyIcon from "assets/icons/copyIcon.svg";
import CheckIcon from "assets/icons/checkIcon.png";
import FlowImg from "assets/flowImg.png";
import alertIcon from "assets/WarningIcon.svg";
import alertIconRed from "assets/warningIconRed.svg";
import SorryIllustration from "assets/SorryIllustration.svg";
import ReactCountryFlag from "react-country-flag";
import {
  getActivateStatus,
  getGiftData,
  getQR,
  getUrls,
  getActivationState,
  getDataFromOrderId,
} from "utils/constants/config";
import PrimaryButton from "components/buttons/PrimaryButton";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ShareModal from "./ShareModal";
import { toast } from "react-toastify";

interface Props {
  show: boolean;
  handleCloseModal: () => void;
  modalData?: any;
  id?: any;
}

const PurchaseModal: FC<Props> = ({
  show,
  handleCloseModal,
  modalData,
  id,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const [isActivate, setIsActivate] = useState<boolean>(false);
  const [giftLoading, setGiftLoading] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [giftUrl, setGiftUrl] = useState<string>("");
  const [dataLimitInBytes, setDataLimitInBytes] = useState<any>("");
  const [iso, setIso] = useState<any>("");
  const [validity, setValidity] = useState<any>("");
  const [rewards, setRewards] = useState<any>("");
  const [orderId, setOrderId] = useState<any>("");
  const [qrImg, setQRImg] = useState<any>("");
  const [flagCountryCode, setFlagCountryCode] = useState<any>("");
  const [selectPlan, setSelectPlan] = useState<any>({ value: 1, label: "1GB" });
  const [type, setType] = useState<number>(0);
  const [copyState, setCopyState] = useState<boolean>(false);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [rewardsForModal, setRewardsForModal] = useState<any>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const mobileView = useMediaQuery("(max-width:768px)");

  let shareData = {
    url: "https://loacakamsd.com",
    text: "You can share the claimable eSIM from GiantConnect",
    title: "GiantConnect Gift Card",
  };

  useEffect(() => {
    if (searchParams.get("orderId") !== null) {
      setType(1);
      checkActivation(searchParams.get("orderId"));
      getDataFromOrderId({ orderId: searchParams.get("orderId") }).then(
        (res: any) => {
          setCountry(res.country);
          setPrice(res.price);
          setRewardsForModal(res.rewards);
        }
      );
    }
    setDataLimitInBytes(searchParams.get("dataLimitInBytes"));
    setIso(searchParams.get("iso"));
    setFlagCountryCode(searchParams.get("flagCountryCode"));
    setOrderId(searchParams.get("orderId"));
    setValidity(searchParams.get("validity"));
    setRewards(searchParams.get("rewards"));
  }, [searchParams]);

  useEffect(() => {
    if (id) {
      setType(22);
      getGiftData({
        giftCode: id,
      }).then((res) => {
        setCountry(res.country);
        setPrice(res.price);
        setRewardsForModal(res.rewards);
        setQRImg(res.data);
        setDataLimitInBytes(res.dataLimitInBytes);
        setIso(res.iso);
        setFlagCountryCode(res.flagCountryCode);
        setOrderId(res.orderId);
      });
    }
  }, [id]);

  useEffect(() => {
    if (modalData) {
      let value = modalData?.dataLimitInBytes / (1024 * 1024) / 1024;
      setSelectPlan({ value: value, label: value.toString() + "GB" });
    }
  }, [modalData]);

  const checkActivation = async (orderId: any) => {
    await getActivationState({ orderId: orderId }).then((res: any) => {
      if (res.isGiftPack) {
        setType(21);
        handleActivate("GIFT");
      } else {
        if (res.status === true && res.data === true) {
          setType(11);
        } else if (res.status === true && res.data === false) {
          setType(1);
        }
      }
    });
  };

  const handleCheckout = async () => {
    await setIsLoading(true);
    (window as any).analytics.track("Clicked on Checkout button", {
      country: modalData?.country,
      dataLimitInBytes: modalData?.dataLimitInBytes,
      price: modalData?.priceInWifiToken,
      ServiceType: modalData?.serviceType,
      rewards: modalData?.bonusRewards,
      userEmail: userEmail,
    });
    (window as any).analytics.identify({
      email: userEmail,
    });

    getUrls({
      email: userEmail,
      dataLimitInBytes: modalData?.dataLimitInBytes,
      iso: modalData?.iso,
    })
      .then((res: any) => {
        window.open(res.data.url, "_self");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const validateEmail = (e: any) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const email = e.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleActivate = async (param: string) => {
    if (param === "GIFT") {
      (window as any).analytics.track("Clicked on Send As Gift button", {
        country: country,
        dataLimitInBytes: searchParams.get("dataLimitInBytes"),
        price: price,
        ServiceType: "4G/LTE",
        rewards: rewardsForModal,
      });
    } else {
      (window as any).analytics.track("Clicked on Activate Now button", {
        country: country,
        dataLimitInBytes: searchParams.get("dataLimitInBytes"),
        price: price,
        ServiceType: "4G/LTE",
        rewards: rewardsForModal,
      });
    }
    param === "GIFT" ? await setGiftLoading(true) : await setIsActivate(true);
    getQR({
      dataLimitInBytes: searchParams.get("dataLimitInBytes"),
      orderId: searchParams.get("orderId"),
      isGiftPack: param === "GIFT" ? true : false,
      iso: iso,
    })
      .then((res) => {
        setIsActivate(false);
        setGiftLoading(false);
        setQRImg(res.data);
        if (param === "GIFT") {
          setType(21);
          setGiftUrl(res.data);
        } else {
          setType(11);
        }
      })
      .catch((err) => {
        setIsActivate(false);
        setGiftLoading(false);
      });
  };

  const getStatus = async (param: any) => {
    (window as any).analytics.track("Clicked on Verify Installation button", {
      country: country,
      dataLimitInBytes: dataLimitInBytes,
      price: price,
      ServiceType: "4G/LTE",
      rewards: rewardsForModal,
    });
    await setIsVerify(true);
    getActivateStatus({
      orderId: orderId,
    })
      .then((res) => {
        if (res.eSimStatus === "INSTALLED") {
          param === "GIFT" ? setType(23) : setType(12);
          (window as any).analytics.track("Installation Verification Success", {
            country: country,
            dataLimitInBytes: dataLimitInBytes,
            price: price,
            ServiceType: "4G/LTE",
            rewards: rewardsForModal,
          });
        } else if (res.eSimStatus === "DELETED") {
          setType(24);
        } else {
          setShowError(true);
        }
        setIsVerify(false);
      })
      .catch((err) => {
        setIsVerify(false);
      });
  };

  console.log(showError);

  return (
    <div>
      <Modal
        open={show}
        onClose={() => {
          if (type !== 1) {
            setType(0);
            setIsChecked(false);
            handleCloseModal();
            setUserEmail("");
            navigate("/");
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.ModalMainContainer>
          <S.ModalHeader
            sx={{
              background: `linear-gradient(113.74deg, ${
                modalData.color ? modalData.color?.start : "#F8D0D4"
              } 9.6%, ${
                modalData.color ? modalData.color?.end : "#FDF0F2"
              } 91.34%)`,
            }}
          >
            <Box>
              {type === 0 ? (
                <S.ModalHeaderLeftText sx={{ marginLeft: "1rem" }}>
                  {modalData?.dataLimitInBytes
                    ? modalData?.dataLimitInBytes / (1024 * 1024) / 1024
                    : dataLimitInBytes / (1024 * 1024) / 1024}
                  GB
                </S.ModalHeaderLeftText>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    justifyContent: "center",
                  }}
                >
                  {type === 12 || type === 23 ? (
                    <img src={ActiveIcon} alt={ActiveIcon} />
                  ) : (
                    <img src={DeactiveIcon} alt={DeactiveIcon} />
                  )}
                  <S.ModalHeaderLeftText>
                    {modalData?.dataLimitInBytes
                      ? modalData?.dataLimitInBytes / (1024 * 1024) / 1024
                      : dataLimitInBytes / (1024 * 1024) / 1024}
                    GB
                  </S.ModalHeaderLeftText>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <S.ModalHeaderRightText>
                {" "}
                {modalData?.iso ?? iso}
              </S.ModalHeaderRightText>
              {/* <img
                src={UsaIcon}
                alt="UsaIcon img"
                style={{ width: "38px", height: "28px" }}
              /> */}
              {modalData?.flag === "Global" || flagCountryCode === "Global" ? (
                <img
                  src={WorldIcon}
                  alt="currency img"
                  style={{ width: "28px", height: "28px" }}
                />
              ) : (
                <ReactCountryFlag
                  countryCode={
                    modalData?.flag ? modalData?.flag : flagCountryCode
                  }
                  style={{ paddingTop: "10px", width: "38px", height: "38px" }}
                />
              )}
            </Box>
            {type !== 1 && (
              <img
                src={CloseIcon}
                alt=""
                onClick={() => {
                  setType(0);
                  handleCloseModal();
                  setIsChecked(false);
                  setUserEmail("");
                  navigate("/");
                }}
                style={{
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  cursor: "pointer",
                }}
              />
            )}
          </S.ModalHeader>
          {type === 0 && (
            <S.ModalBody>
              <S.DataContainer>
                <S.TitleContainer>
                  <S.TitleText>Active Period:</S.TitleText>
                  <S.ValueText>30 Days</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>Service Type:</S.TitleText>
                  <S.ValueText>4G LTE</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>GIANT Rewards:</S.TitleText>
                  <S.ValueText>{modalData?.bonusRewards}</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>Carrier(s):</S.TitleText>
                  <S.ValueText>{modalData?.carrier}</S.ValueText>
                </S.TitleContainer>
              </S.DataContainer>
              <Divider sx={{ border: "1px solid #DEE7FD", width: "100%" }} />

              <S.EmailContainer>
                <S.EmailInput
                  type="email"
                  placeholder="Email address"
                  //required="required"
                  value={userEmail}
                  onChange={(e: any) => {
                    setUserEmail(e.target.value);
                    validateEmail(e);
                  }}
                />
                <S.ContentTextEmail>
                  Email is required to claim your rewards and access your eSIM
                  plan in the GIANT Connect mobile app
                </S.ContentTextEmail>
              </S.EmailContainer>

              <Divider sx={{ border: "1px solid #DEE7FD", width: "100%" }} />

              <S.DynamicContainer>
                <S.AggrementText>
                  4G LTE plans are non-refundable. Please ensure your device is
                  unlocked AND supports eSim. To verify, you can check the&nbsp;
                  <a
                    style={{
                      fontWeight: 700,
                      fontSize: "1rem",
                      lineHeight: "21px",
                      color: "#45B549",
                      textDecoration: "none",
                    }}
                    href="https://giantconnect.zendesk.com/hc/en-us/articles/4419817382679-e-sim-FAQs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    eSim FAQs {"  "}
                  </a>
                  and&nbsp;
                  <Link
                    style={{
                      fontWeight: 700,
                      fontSize: "1rem",
                      lineHeight: "21px",
                      color: "#45B549",
                      textDecoration: "none",
                    }}
                    // href="https://giantconnect.zendesk.com/hc/en-us/articles/4419826397847-How-to-setup-e-sim"
                    // target="_blank"
                    // rel="noreferrer"
                    to="/installationguide"
                  >
                    eSim setup guide.
                  </Link>{" "}
                </S.AggrementText>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "2rem",
                    gap: "0.5rem",
                  }}
                >
                  <S.CheckBox
                    type="checkbox"
                    value={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />{" "}
                  <S.AggreeText>
                    {" "}
                    I agree with the above terms and conditions
                  </S.AggreeText>
                </Box>
                <S.MainContent sx={{ marginTop: "1rem" }}>
                  <Box
                    style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}
                  >
                    <S.ConfirmBtn
                      onClick={() => {
                        // setType(1);
                        handleCheckout();
                      }}
                      disabled={!isChecked || !isValid || isLoading}
                    >
                      {isLoading === true ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Checkout"
                      )}
                    </S.ConfirmBtn>
                    <img src={StripeLogo} alt={StripeLogo} />
                  </Box>
                  <S.GntText>
                    ${modalData?.priceInWifiToken?.toFixed(2)}
                  </S.GntText>
                </S.MainContent>
              </S.DynamicContainer>
            </S.ModalBody>
          )}
          {type === 1 && (
            <S.GiftModalBody>
              <S.GiftModalBodyText>
                Congratulations! Now connect to earn GIANT rewards!
              </S.GiftModalBodyText>
              <S.GiftContainer>
                <S.GiftContainerBox>
                  <img src={ActiveImg} alt={ActiveImg} />
                  <S.ConfirmBtn
                    disabled={isActivate}
                    onClick={() => {
                      handleActivate("ACTIVATE");
                    }}
                  >
                    {isActivate === true ? (
                      <CircularProgress size={30} />
                    ) : (
                      "Activate Now"
                    )}
                  </S.ConfirmBtn>
                </S.GiftContainerBox>
                <S.GiftContainerBox>
                  <img src={SendGiftImg} alt={SendGiftImg} />
                  <S.ConfirmBtn
                    disabled={giftLoading}
                    onClick={() => {
                      handleActivate("GIFT");
                    }}
                  >
                    {giftLoading === true ? (
                      <CircularProgress size={30} />
                    ) : (
                      "Send as Gift"
                    )}
                  </S.ConfirmBtn>
                </S.GiftContainerBox>
              </S.GiftContainer>
              <S.GiftContainerText
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <span>
                  {" "}
                  <img src={alertIcon} alt="" />{" "}
                </span>{" "}
                Do NOT close this window or you must contact us to retrieve your
                eSIM plan
              </S.GiftContainerText>
            </S.GiftModalBody>
          )}
          {type === 11 && (
            <S.CustomDynamicContainer>
              {/* <S.NotDoneContainer>
                <Box sx={{ paddingTop: "1.5rem" }}>
                  <S.NotDoneText>You're not done yet!</S.NotDoneText>
                  <S.InstructionText onClick={() => setType(12)}>
                    Click here for installation Instructions
                  </S.InstructionText>
                </Box>
                <Typography>
                  Want to activate later? Download the GIANT
                  <br /> Connect mobile app and login with the email
                  <br /> address you provided on purchase.
                </Typography>
              </S.NotDoneContainer> */}
              <S.InstallationContainer>
                <Box sx={{ height: "100%" }}>
                  <img src={FlowImg} alt="flowimg" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.9rem",
                  }}
                >
                  <Box>
                    <S.InstallationMainText>
                      Scan QR code
                    </S.InstallationMainText>
                    <S.InstallationSubText>
                      We've emailed you the following QR code.
                      <br /> Please scan it with your phone's camera.
                    </S.InstallationSubText>
                  </Box>
                  <Box>
                    <S.InstallationMainText>
                      Follow the prompts to install your eSIM
                    </S.InstallationMainText>
                    <S.InstallationSubText>
                      Set the eSIM as your primary or secondary data plan.
                      <br />
                      <Link
                        to="/installationguide"
                        style={{ color: "#45B549", cursor: "pointer" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Check detailed instructions
                      </Link>{" "}
                      for your device.
                    </S.InstallationSubText>
                  </Box>
                  <Box>
                    <S.InstallationMainText>
                      Connect and earn
                    </S.InstallationMainText>
                    <S.InstallationSubText>
                      Track usage and earn GIANT rewards in the
                      <br /> GIANT Connect mobile app
                    </S.InstallationSubText>
                  </Box>
                  <S.ConfirmBtn
                    style={{ height: "50px", width: "260px" }}
                    disabled={isVerify}
                    onClick={() => {
                      getStatus("ACTIVATE");
                    }}
                  >
                    {isVerify === true ? (
                      <CircularProgress size={30} />
                    ) : (
                      "Verify Installation"
                    )}
                  </S.ConfirmBtn>
                  {showError && (
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          left: "-3rem",
                        }}
                      >
                        <span
                          style={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                        >
                          <img src={alertIconRed} alt="" />{" "}
                        </span>
                        eSIM not installed.&nbsp;
                        <a
                          href="#"
                          style={{
                            textDecoration: "none",
                            color: "#B20000",
                            fontWeight: "700",
                          }}
                        >
                          Click here &nbsp;
                        </a>{" "}
                        to for detailed instructions.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </S.InstallationContainer>
              <Box
                // sx={{
                //   display: "flex",
                //   justifyContent: "center",
                // }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignSelf: "flex-start",
                  gap: "1rem",
                  justifyContent: "center",
                }}
              >
                <img
                  src={qrImg !== "" ? qrImg : QRImg}
                  alt="qr-img"
                  style={{
                    width: "221.31px",
                    height: "222.5px",
                    border: "5.98131px solid #CED6E0",
                    borderRadius: "8px",
                    padding: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                    fontStyle: "italic",
                    lineHeight: "17px",
                    color: "#414141",
                  }}
                >
                  Your eSIM can only be installed once.
                  <br /> It cannot be reinstalled if removed from <br />
                  your device.
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  alignSelf: "flex-start",
                  justifyContent: "center",
                }}
              >
                <img
                  src={qrImg !== "" ? qrImg : QRImg}
                  alt="qr-img"
                  style={{
                    width: "221.31px",
                    height: "222.5px",
                    border: "5.98131px solid #CED6E0",
                    borderRadius: "8px",
                    // padding: "1rem",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  fontStyle: "italic",
                  lineHeight: "17px",
                  color: "#414141",
                }}
              >
                Your eSIM can only be installed once.
                <br /> It cannot be reinstalled if removed from <br />
                your device.
              </Typography> */}
              {/* <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.2rem",
                  fontWeight: 400,
                  fontSize: "13px",
                  lineHeight: "17px",
                  color: "rgba(65, 65, 65, 0.75)",
                }}
              >
                <img
                  src={WarningIcon}
                  alt={WarningIcon}
                  style={{
                    width: "20.46px",
                    height: "18.25px",
                  }}
                />
                Your eSIM can only be installed once. It cannot be reinstalled
                if removed from your device.
              </Typography> */}
            </S.CustomDynamicContainer>
          )}
          {type === 21 && (
            <S.ShareBody>
              <S.CongratsText>
                Share this unique URL with your friend.
              </S.CongratsText>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={ShareImg}
                  alt="ShareImg"
                  style={{
                    width: "287.41px",
                    height: "195.71px",
                  }}
                />
              </Box>
              <S.CopyContainer>
                <S.LinkIconContainer>
                  <img
                    src={linkIcon}
                    style={{
                      height: "15px",
                      width: "15px",
                      marginRight: "0.5rem",
                    }}
                    alt=""
                  />
                </S.LinkIconContainer>
                <S.CopyInput
                  readOnly
                  onClick={(e: any) => e.target.select()}
                  value={giftUrl}
                />
                <Tooltip
                  title={`${copyState ? "copied!" : "copy to clipboard"}`}
                >
                  <S.CopyIconContainer
                    onClick={() => {
                      setCopyState(true);
                      setTimeout(() => {
                        setCopyState(false);
                      }, 2000);
                      navigator.clipboard.writeText(giftUrl);
                      (window as any).analytics.track("Gift URL Copied", {
                        country: country,
                        dataLimitInBytes: searchParams.get("dataLimitInBytes"),
                        price: price,
                        ServiceType: "4G/LTE",
                        rewards: rewardsForModal,
                      });
                    }}
                  >
                    {!copyState ? (
                      <img src={copyIcon} alt="" />
                    ) : (
                      <img
                        src={CheckIcon}
                        style={{ height: "15px", width: "15px" }}
                        alt=""
                      />
                    )}
                  </S.CopyIconContainer>
                </Tooltip>
                {mobileView ? (
                  <S.ShareIconContainer
                    onClick={() => navigator.share(shareData)}
                  >
                    <img src={ShareIcon} alt="" />
                  </S.ShareIconContainer>
                ) : (
                  <S.ShareIconContainer onClick={() => setShareModal(true)}>
                    <img src={ShareIcon} alt="" />
                  </S.ShareIconContainer>
                )}
              </S.CopyContainer>
              <S.NoteText>
                Note: On clicking the URL they will be able to install the data
                plan instantly.
              </S.NoteText>
            </S.ShareBody>
          )}
          {type === 12 && (
            <S.SuccessModalBody>
              <S.SuccessDataContainer>
                <S.TitleContainer>
                  <S.TitleText>Active Period:</S.TitleText>
                  <S.ValueText>{validity} Days</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>Service Type:</S.TitleText>
                  <S.ValueText>4G LTE</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>GIANT Rewards:</S.TitleText>
                  <S.ValueText>{rewards}</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>Carrier:</S.TitleText>
                  <S.ValueText>AT & Mobility</S.ValueText>
                </S.TitleContainer>
              </S.SuccessDataContainer>
              <Divider sx={{ border: "1px solid #DEE7FD", width: "100%" }} />

              <S.SuccessContentBox>
                <S.MobileGiftHeaderText>
                  You successfully activated your plan!
                </S.MobileGiftHeaderText>
                <img
                  src={SendGiftImg}
                  alt={SendGiftImg}
                  style={{
                    width: "358px",
                    height: "185.53px",
                  }}
                />
                <S.SuccessFooterText>
                  You can check your plan status and GIANT rewards in the
                  <br />
                  <a
                    style={{
                      color: "#45B549",
                      fontWeight: 700,
                      fontFamily: "DM Sans",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    href="https://wific0in.app.link/SCPal1gVbrb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    GIANT Connect mobile app
                  </a>{" "}
                  using your provided email address
                </S.SuccessFooterText>
              </S.SuccessContentBox>
            </S.SuccessModalBody>
          )}
          {type === 22 && (
            // <S.GiftModalBody>
            //   <S.GiftModalBodyText>
            //     You’ve received free mobile data!
            //   </S.GiftModalBodyText>
            //   <S.GiftContainer>
            //     <Box>
            //       <img
            //         src={ActiveImg}
            //         alt={ActiveImg}
            //         style={{
            //           width: "260px",
            //           height: "274.96px",
            //         }}
            //       />
            //     </Box>
            //     <Box sx={{ display: "grid", placeContent: "center" }}>
            //       <img
            //         src={QRImg}
            //         alt="qr-img"
            //         style={{
            //           width: "221.31px",
            //           height: "222.5px",
            //           border: "5.98131px solid #CED6E0",
            //           borderRadius: "8px",
            //           padding: "1rem",
            //         }}
            //       />
            //     </Box>
            //   </S.GiftContainer>
            //   <S.MobileText onClick={() => setType(23)}>
            //     Click here for installation instructions
            //   </S.MobileText>
            // </S.GiftModalBody>
            <>
              <S.RecievedText>You've received free mobile data!</S.RecievedText>
              <S.CustomDynamicContainerGift>
                <S.InstallationContainer>
                  <Box sx={{ height: "100%" }}>
                    <img src={FlowImg} alt="flowimg" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.9rem",
                    }}
                  >
                    <Box>
                      <S.InstallationMainText>
                        Scan QR Code
                      </S.InstallationMainText>
                      <S.InstallationSubText>
                        Use your phone camera to scan this QR code.
                      </S.InstallationSubText>
                    </Box>
                    <Box>
                      <S.InstallationMainText>
                        Follow the prompts to install your eSIM
                      </S.InstallationMainText>
                      <S.InstallationSubText>
                        Set the eSIM as your primary or secondary data plan.
                        <br />
                        <Link
                          to="/installationguide"
                          style={{ color: "#45B549", cursor: "pointer" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Check detailed instructions
                        </Link>{" "}
                        for your device.
                      </S.InstallationSubText>
                    </Box>
                    <Box>
                      <S.InstallationMainText>
                        Connect and Earn
                      </S.InstallationMainText>
                      <S.InstallationSubText>
                        Track usage and earn GIANT rewards in the
                        <br /> GIANT Connect mobile app
                      </S.InstallationSubText>
                    </Box>
                    <S.ConfirmBtn
                      style={{ height: "50px", width: "260px" }}
                      disabled={isVerify}
                      onClick={() => {
                        getStatus("GIFT");
                      }}
                    >
                      {isVerify === true ? (
                        <CircularProgress size={30} />
                      ) : (
                        "Verify Installation"
                      )}
                    </S.ConfirmBtn>
                    {showError && (
                      <Box sx={{ position: "relative" }}>
                        <Typography
                          sx={{
                            fontWeight: "400",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            left: "-3rem",
                          }}
                        >
                          <span
                            style={{
                              marginRight: "0.5rem",
                              marginTop: "0.5rem",
                            }}
                          >
                            <img src={alertIconRed} alt="" />{" "}
                          </span>
                          eSIM not installed.
                          <a
                            href="#"
                            style={{
                              textDecoration: "none",
                              color: "#B20000",
                              fontWeight: "700",
                            }}
                          >
                            Click here &nbsp;
                          </a>{" "}
                          to for detailed instructions.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </S.InstallationContainer>
                <Box
                  // sx={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  // }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignSelf: "flex-start",
                    gap: "1rem",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      border: "5.98131px solid #CED6E0",
                      borderRadius: "8px",
                      width: "260px",
                      height: "256px",
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
                    {qrImg !== "" ? (
                      <img
                        style={{ width: "250px", height: "240px" }}
                        src={qrImg}
                        alt="qr-img"
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      fontStyle: "italic",
                      lineHeight: "17px",
                      color: "#414141",
                    }}
                  >
                    Your eSIM can only be installed once.
                    <br /> It cannot be reinstalled if removed from <br />
                    your device.
                  </Typography>
                </Box>
                {/* <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.2rem",
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "17px",
                    color: "rgba(65, 65, 65, 0.75)",
                  }}
                >
                  <img
                    src={WarningIcon}
                    alt={WarningIcon}
                    style={{
                      width: "20.46px",
                      height: "18.25px",
                    }}
                  />
                  Your eSIM can only be installed once. It cannot be reinstalled
                  if removed from your device.
                </Typography> */}
              </S.CustomDynamicContainerGift>
            </>
          )}
          {type === 23 && (
            <S.GiftModalBody>
              <S.MobileGiftHeaderText>
                You successfully activated your plan!
              </S.MobileGiftHeaderText>
              <img
                src={SendGiftImg}
                alt={SendGiftImg}
                style={{
                  width: "358px",
                  height: "185.53px",
                }}
              />
              <S.MobileGiftFooterText>
                Now it’s your turn! Earn GIANT when you gift data.
              </S.MobileGiftFooterText>
              <S.ConfirmBtn
                onClick={() => {
                  setType(0);
                  handleCloseModal();
                  setIsChecked(false);
                  setUserEmail("");
                  navigate("/");
                  (window as any).analytics.track(
                    "Clicked On Gift and Earn button"
                  );
                }}
              >
                Gift and Earn
              </S.ConfirmBtn>
            </S.GiftModalBody>
          )}
          {type === 24 && (
            <S.GiftFailureModalBody>
              <S.MobileGiftHeaderText>
                We're sorry. Something's not right
              </S.MobileGiftHeaderText>
              <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                <S.FailureModalLeft>
                  <img src={SorryIllustration} alt="" />
                </S.FailureModalLeft>
                <S.FailureModalRight>
                  <S.FailureSubText>
                    Please check your email for the <br /> QR code and follow
                    the detailed
                    <br />{" "}
                    <a href="#" style={{ color: "#45B549" }}>
                      installation guide.
                    </a>
                  </S.FailureSubText>
                  <S.FailureSubText>
                    Still having problems?{" "}
                    <a href="#" style={{ color: "#45B549" }}>
                      Contact us
                    </a>{" "}
                  </S.FailureSubText>
                </S.FailureModalRight>
              </Box>
            </S.GiftFailureModalBody>
          )}
        </S.ModalMainContainer>
      </Modal>
      {shareModal && (
        <ShareModal
          setType={setType}
          shareUrl={giftUrl}
          onClose={() => setShareModal(false)}
        />
      )}
    </div>
  );
};

export default PurchaseModal;
