import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";

export namespace S {
  export const InstallationMainContainer = styled(Box)(
    ({ theme }: any) => ({})
  );
  export const MainHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "53px",
    lineHeight: "60px",
    textAlign: "center",
    flexWrap: "wrap",
    padding: "1.5625rem 1rem 0 1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "34px",
      lineHeight: "40px",
    },
  }));
  export const InstallationHeader = styled(Box)(({ theme }: any) => ({
    // minHeight: "37.5rem",
    gap:"1.5rem",
    height: "100%",
    width:"100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    // "@media (max-width: 1200px)": {
    //   gridTemplateColumns: "1fr",
    //   marginBottom: "1rem",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: "28.375rem",
    // },
  }));
  export const InstallationToggleContainer = styled(Box)(({ theme }: any) => ({
    position: "absolute",
    bottom: "-1.5rem",
  }));
  export const InstallationToggleAndroid = styled(Button)(({ theme }: any) => ({
    borderRadius: "1260px",
    width: "197px",
    height: "45px",
    textTransform: "none",
    animationDuration: "10s",
    marginLeft: "3rem",
    "@media (max-width: 445px)": {
      width: "150px",
    },
  }));
  export const InstallationToggleIos = styled(Button)(({ theme }: any) => ({
    borderRadius: "1260px",
    width: "197px",
    height: "45px",
    posiion: "absolute",
    right: "3rem",
    textTransform: "none",
    animationDuration: "10s",
    "@media (max-width: 445px)": {
      width: "150px",
      right: "2rem",
    },
  }));
  export const InstallationSubHeader = styled(Box)(({ theme }: any) => ({
    minHeight: "19.4375rem",
    height: "100%",
    background: theme.palette.primary.contrastText,
    padding: "59px 0 38px 0",
    boxSizing: "border-box",
  }));
  export const InstallationSubText = styled(Typography)(({ theme }: any) => ({
    fontSize: "24px",
    lineHeight: "31px",
    color: theme.palette.text.primary,
  }));
  export const InstallationHeadText = styled(InstallationSubText)(
    ({ theme }: any) => ({
      fontWeight: "700",
      lineHeight: "28px",
      color: "#0B213E",
    })
  );
  export const InstallationRound = styled(Box)(({ theme }: any) => ({
    height: "31px",
    width: "31px",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "grid",
    placeContent: "center",
  }));
  export const InstallationChipContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "1rem",
    margin: "34px 0 50px 0 ",
    padding:"0.8rem"
  }));
  export const InstallationChip = styled(Box)(({ theme }: any) => ({
    background: "#DBE8F1",
    borderRadius: "100px",
    height: "41px",
    minWidth: "202px",
    display: "grid",
    padding: "0.5rem",
    placeContent: "center",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "31px",
    color: "#414141",
  }));
  export const ImportantNoteText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    padding:"0.5rem"
  }));
  export const IosMainContainer = styled(Box)(({ theme }: any) => ({

  }));
  export const IosSubContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "wrap",
    padding: "2rem 0",
    maxWidth:"1440px",
    margin:"auto"
    // display: "grid",
    // flexDirection: "column",
    // gridTemplateColumns: "1fr 1fr",
    // "@media (max-width: 600px)": {
      // gridTemplateColumns: "1fr",
    // },
  }));
  export const LeftContainer = styled(Box)(({ theme }: any) => ({
    width: "50%",
    minWidth: "300px",
    minHeight: "500px",
    position: "relative",
    padding: "2rem",
    gap: "0.8rem",
    "@media (max-width: 760px)": {
      width: "100%",
      minHeight: "0px",
    },
  }));
  export const RightContainer = styled(Box)(({ theme }: any) => ({
    width: "50%",
    minWidth: "300px",
    minHeight: "500px",
    position: "relative",
    padding: "2rem",
    gap: "0.8rem",
    "@media (max-width: 760px)": {
      width: "100%",
      minHeight: "0px",
    },
  }));
  export const AndroidMainContainer = styled(Box)(({ theme }: any) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media (max-width: 600px)": {
      gridTemplateColumns: "1fr",
    },
  }));
  export const AndroidLeftContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    padding: "3.625rem",
    position: "relative",
  }));
  export const GreenRound = styled(Box)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: "36px",
    width: "36px",
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    // position:"relative"
  }));
  export const AndroidInstallationSubtext = styled(Typography)(
    ({ theme }: any) => ({
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "28px",
    })
  );
  export const AndroidInstallationSubContainer = styled(Box)(
    ({ theme }: any) => ({
      display: "grid",
      gridTemplateColumns: "5% 95%",
      gap: "1.875rem",
    })
  );

  export const AndroidRightContainer = styled(Box)(({ theme }: any) => ({
    padding: "58px 112px 58px 0",
    gap: "4.4375rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "@media (max-width: 600px)": {
      padding: "58px 5rem 58px 5rem",
    },
  }));
  export const InstallationSubFooterContainer = styled(Box)(({ theme }: any) => ({
    minHeight: "629px",
    height: "100%",
    background: theme.palette.primary.contrastText,
    display: "grid",
    gap: "1rem",
    gridTemplateColumns: "1.5fr 1fr",
    "@media (max-width: 760px)": {
      gridTemplateColumns: "1fr",
      paddingBottom:"2rem"
    },
  }));
  export const InstallationSubFooterTextContainer = styled(Box)(({ theme }: any) => ({
    padding: "113px 0 147px 99px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: "1rem",
    "@media (max-width: 760px)": {
      padding: "2rem",
    },
  }));
  export const InstallationSubFooterMainText = styled(Box)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "53px",
    lineHeight: "60px",
    "@media (max-width: 760px)": {
      fontSize: "34px",
      lineHeight: "40px",
    },
  }));
  export const InstallationSubFooterSubText = styled(InstallationSubFooterMainText)(
    ({ theme }: any) => ({
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "31px",
      "@media (max-width: 760px)": {
        fontSize: "24px",
        lineHeight: "31px",
      },
    })
  );
  export const AppstoreContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxWidth: "500px",
    gap: "1rem",
    "@media (max-width: 1200px)": {
      justifyContent: "center",
      marginTop: "1rem",
      width: "100%",
    },
  }));
}
