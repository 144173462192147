import { FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
// import MainLogo from "assets/icons/MainLogo.svg";
import HeaderLogoGiantConnect from "assets/HeaderLogoGiantConnect.svg";
import MobileLogo from "assets/GIantMobileLogo.svg";
import { Link, useLocation } from "react-router-dom";

const Header: FC = () => {
  const match = useMediaQuery("(max-width:600px)");
  const location = useLocation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position="static">
        <HeaderContainer container>
          <LogoContainer item>
            <Link to="/">
              <img src={match ? MobileLogo : HeaderLogoGiantConnect} alt="" />
            </Link>
          </LogoContainer>
          {location.pathname !== "/installationguide" && (
            <GuideText>
              <Link
                // href="https://giantconnect.zendesk.com/hc/en-us/articles/4419826397847"
                // style={{ textDecoration: "none", color: "#0B213E" }}
                // target="_blank"
                // rel="noreferrer"
                to="/installationguide"
                style={{ textDecoration: "none", color: "#0B213E" }}
              >
                Installation Guide
              </Link>
            </GuideText>
          )}
        </HeaderContainer>
      </CustomAppBar>
    </Box>
  );
};

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow:
    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)",
  color: theme.palette.text.primary,
  position: "fixed",
  top: 0,
  zIndex: 99,
  height: "5rem",
}));

const HeaderContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0rem 2rem",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: "0rem 1rem 0 1rem",
  },
}));
const LogoContainer = styled(Grid)(({ theme }) => ({
  cursor: "pointer",
}));
const GuideText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "22px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

export default Header;
