import styled from "@emotion/styled";
import { Box, Card, Typography } from "@mui/material";
import { FC } from "react";
import WhiteSemiColon from "assets/WhiteSemicolon.svg";

interface Props {
  data: any;
}

const TestimonialCard: FC<Props> = ({ data }) => {
  return (
    <TestimonialCardContainer>
      <TestimonialImgContainer>
        <img src={data?.img} alt="" style={{ width: "100%" }} />
      </TestimonialImgContainer>
      <TestimoniaContentContainer>
        <SemicolonContainer>
          <img
            src={WhiteSemiColon}
            alt=""
            style={{ height: "18.45px", width: "24.81px" }}
          />
        </SemicolonContainer>
        <DescriptionText sx={{ paddingBottom: "16px" }}>
          {data.description}
        </DescriptionText>
        <NameText sx={{ paddingBottom: "6px" }}>{data.name}</NameText>
        <SubNameText>{data.subname}</SubNameText>
      </TestimoniaContentContainer>
    </TestimonialCardContainer>
  );
};

export default TestimonialCard;

const TestimonialCardContainer = styled(Card)(({ theme }: any) => ({
  background: theme.palette.primary.contrastText,
  borderRadius: "8px",
  padding: "0 0 1rem 0",
  minWidth: "225px",
  width: "236px",
  minHeight: "361px",
  display: "flex",
  flexDirection: "column",
  //   [theme.breakpoints.up("md")]: {
  //     maxHeight: 300,
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     width: "350px",
  //   },
}));

const TestimonialImgContainer = styled(Box)(({ theme }: any) => ({
  width: "100%",
}));

const TestimoniaContentContainer = styled(Box)(({ theme }: any) => ({
  padding: "39px 5px 26px 19px",
  position: "relative",
}));
const SemicolonContainer = styled(Box)(({ theme }: any) => ({
  background: theme.palette.primary.main,
  height: "41px",
  width: "41px",
  borderRadius: "50%",
  display: "grid",
  placeContent: "center",
  position: "absolute",
  top: "-21px",
  left: "12px",
}));
const DescriptionText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
}));
const NameText = styled(DescriptionText)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "23px",
}));
const SubNameText = styled(DescriptionText)(({ theme }: any) => ({
  fontWeight: 400,
  color: theme.palette.primary.main,
  fontStyle: "italic",
}));
