import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";

export namespace S {
  export const ModalMainContainer = styled(Box)(({ theme }: any) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflowX: "hidden",
    overflowY: "auto",
    outline: "none",
    border: "none",
    "@media (min-width: 876px)": {
      minHeight: 472,
      minWidth: 810,
    },
    "@media (max-width: 876px)": {
      width: "80%",
      maxHeight: "80%",
    },
  }));
  export const ModalHeader = styled(Box)(({ theme }: any) => ({
    minHeight: "84px",
    // background: "linear-gradient(113.74deg, #F8D0D4 9.6%, #FDF0F2 91.34%)",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 4rem 0 2rem",
    borderRadius: "8px 8px 0px 0px",
    gap: "0.5rem",
    flexWrap: "wrap",
  }));
  export const ModalHeaderLeftText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "2.25rem",
    lineHeight: "2.9375rem",
    color: "#0B213E",
  }));
  export const ModalHeaderRightText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "42px",
    textAlign: "right",
    marginRight: "0.5rem",
    color: "#0B213E",
  }));

  export const ModalBody = styled(Box)(({ theme }: any) => ({
    maxHeight: "80%",
    padding: "2rem 3rem",
    display: "flex",
    gap: "1.5rem",
    flexDirection: "column",
    boxSizing: "border-box",
  }));

  export const DataContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "1rem",
  }));
  export const TitleContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    gap: "0.5rem",
  }));
  export const TitleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.4375rem",
    color: "#0B213E",
  }));
  export const ValueText = styled(TitleText)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "23px",
    textAlign: "right",
  }));
  export const EmailContainer = styled(Box)(({ theme }: any) => ({
    // justifyContent: "space-between",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    gap: "1rem",
    flexWrap: "wrap",
  }));
  export const EmailInput = styled.input(({ theme }: any) => ({
    height: "42px",
    width: "347px",
    background: "rgba(206, 214, 224,0.25)",
    color: "#45B549",
    border: "none",
    outline: "none",
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "8px",

    "::placeholder": {
      fontSize: "16px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "95%",
      color: "#66717B",
    },
  }));
  export const ContentTextEmail = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18px",
    color: "#66717B",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  }));
  export const DynamicContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "75%",
    //   paddingTop: "1.5rem",
  }));
  export const AggreeText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    color: "#0B213E",
    paddingLeft: "5px",
  }));
  export const AggrementText = styled(AggreeText)(({ theme }: any) => ({
    color: "#66717B",
    fontSize: "15px",
    lineHeight: "21px",
  }));
  export const CheckBox = styled.input(({ theme }: any) => ({
    width: "21px",
    height: "21px",
    border: "1px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: "0.25rem",
  }));
  export const MainContent = styled(Box)(({ theme }: any) => ({
    gap: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  }));
  export const GntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "42px",
    lineHeight: "55px",
    color: theme.palette.text.primary,
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      fontSize: "2rem",
    },
  }));
  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    color: theme.palette.common.white,
    minWidth: "17rem !important",
    height: "50px",
    boxShadow: "none",
    border: "none",
    fontWeight: 700,
    padding: "0rem 0.625rem",
    fontSize: "1.125rem",
    lineHeight: "1.4375rem",
    textTransform: "capitalize",
    "&:hover": {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    "&:disabled": {
      background: "#C5C5C5",
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.only("sm")]: {
      minWidth: "12rem !important",
    },
    [theme.breakpoints.only("xs")]: {
      minWidth: "12rem !important",
    },
  }));

  export const GiftModalBody = styled(Box)(({ theme }: any) => ({
    maxHeight: "80%",
    padding: "2rem 3rem",
    display: "flex",
    gap: "2rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  }));

  export const GiftFailureModalBody = styled(GiftModalBody)(({ theme }: any) => ({
    padding: "2.6875rem 0 2.25rem 0",
    gap: "0",
  }));
  export const FailureSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "400",
    fontSize: "1.5rem",
    lineHeight: "1.9375rem",
  }));
  export const FailureModalLeft = styled(GiftModalBody)(({ theme }: any) => ({
    width: "50%",
  }));
  export const FailureModalRight = styled(GiftModalBody)(({ theme }: any) => ({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding:"2rem 0"
  }));

  export const GiftModalBodyText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    color: "#0B213E",
  }));
  export const GiftContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    gap: "1rem",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    boxSizing: "border-box",
    flexWrap: "wrap",
  }));

  export const GiftContainerBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "space-between",
    height: "13rem",
  }));
  export const GiftContainerText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: "-0.02em",
    color: "#66717B",
  }));
  export const CustomDynamicContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    height: "100%",
    padding: "2rem 2rem 1rem 2rem",
    boxSizing: "border-box",
    gap: "2rem",
    maxWidth: "810px",
  }));

  export const CustomDynamicContainerGift = styled(CustomDynamicContainer)(
    ({ theme }: any) => ({
      padding: "0 2rem 2rem 1rem",
      gap: "1rem",
    })
  );

  export const NotDoneContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "3rem",
    minHeight: "256px",
    boxSizing: "border-box",
  }));
  export const NotDoneText = styled(Typography)(({ theme }: any) => ({
    fontSize: "1.5rem",
    fontWeight: 700,
  }));

  export const InstructionText = styled(NotDoneText)(({ theme }: any) => ({
    fontSize: "1.125rem",
    color: theme.palette.primary.main,
  }));

  export const ShareBody = styled(Box)(({ theme }: any) => ({
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    padding: "2rem 4rem",
    display: "flex",
    flexDirection: "column",
    maxHeight: "80%",
    boxSizing: "border-box",
  }));
  export const CopyInput = styled.input(({ theme }: any) => ({
    background: "#f4f5f7",
    color: theme.palette.primary.main,
    width: "80%",
    border: "none",
    borderRadius: "10px 0 0 10px",
    padding: "1rem 2rem 1rem 3rem",
    fontSize: "1.125rem",
    // borderLeft:"1px solid red",
    fontWeight: 700,
    height: "47px",
    boxSizing: "border-box",
    "&:focus": {
      outline: "none",
    },
  }));
  export const CopyContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    position: "relative",
    width: "100%",
  }));
  export const CopyIconContainer = styled(Box)(({ theme }: any) => ({
    display: "grid",
    placeContent: "center",
    width: "2.9375rem",
    // height: '100%',
    borderRadius: "0 10px 10px 0",
    background: theme.palette.primary.main,
  }));
  export const LinkIconContainer = styled(Box)(({ theme }: any) => ({
    // display: 'grid',
    // placeContent: 'center',
    // padding: '16px',
    // background: '#f4f5f7',
    height: "15px",
    left: "1rem",
    position: "absolute",
    top: "33%",
    borderRight: "1px solid #C3C4C6",
    // borderRadius: '10px 0 0 10px',
  }));
  export const CongratsText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
  }));
  export const ShareIconContainer = styled(Box)(({ theme }: any) => ({
    height: "2.9375rem",
    width: "2.9375rem",
    display: "grid",
    placeContent: "center",
    background: theme.palette.primary.main,
    borderRadius: "10px",
    marginLeft: "10px",
  }));
  export const NoteText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "17px",
  }));
  export const SuccessModalBody = styled(ModalBody)(({ theme }: any) => ({
    gap: "2rem",
  }));
  export const SuccessDataContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "1.5rem",
  }));
  export const SuccessTitleContainer = styled(Box)(({ theme }: any) => ({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    "@media (max-width: 576px)": {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  }));
  export const SuccessTitleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "400",
    fontSize: "1.125rem",
    lineHeight: "1.4375rem",
  }));
  export const SuccessValueText = styled(TitleText)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "1.5rem",
  }));
  export const SuccessContainer = styled(Box)(({ theme }: any) => ({
    background: "#f3f5f7",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    "@media (min-width: 876px)": {
      height: "13rem",
      width: "46.4375rem",
    },
    "@media (max-width: 876px)": {
      maxWidth: "80%",
      padding: "4rem",
      textAlign: "center",
    },
  }));
  export const SuccessContentBox = styled(Box)(({ theme }: any) => ({
    display: "flex",
    gap: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    boxSizing: "border-box",
  }));
  export const SuccessFooterText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#66717B",
  }));
  export const SuccessText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "1.5rem",
  }));
  export const StatusText = styled(Typography)(({ theme }: any) => ({
    fontSize: "1rem",
    fontWeight: 400,
    marginTop: "1rem",
  }));
  export const MobileText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    color: "#45B549",
  }));
  export const MobileGiftHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "31px",
    color: "#000000",
  }));
  export const MobileGiftFooterText = styled(MobileGiftHeaderText)(
    ({ theme }: any) => ({
      fontWeight: 400,
    })
  );
  export const RecievedText = styled(Typography)(({ theme }: any) => ({
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "31px",
    padding: "1rem 4.5rem",
  }));
  export const InstallationContainer = styled(Box)(({ theme }: any) => ({
    height: "100%",
    // width: "50%",
    display: "flex",
    gap: "1.5rem",
  }));
  export const InstallationMainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "21px",
    marginBottom: "0.3rem",
  }));
  export const InstallationSubText = styled(InstallationMainText)(
    ({ theme }: any) => ({
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
      color: "#414141",
    })
  );
  export const ShareModalMainContainer = styled(Box)(({ theme }: any) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    maxHeight: "80%", //502,
    overflowX: "hidden",
    overflowY: "auto",
  }));
}
