import {
  FOOTER_LINKS_DATATYPE,
  HOW_IT_WORKS_DATATYPE,
  INSTALLATION_CHIP_DATA_DATATYPE,
  POPULAR_COUNTRIES_TYPE,
  SOCIAL_ICONSTYPE,
  TESTIMONIAL_CARD_DATATYPE,
} from "utils/types";
import TwitterLogo from "assets/icons/TwitterIcon.svg";
import LinkedinLogo from "assets/icons/LinkedInIcon.svg";
import TelegramLogo from "assets/icons/TelegramIcon.svg";
import FacebookLogo from "assets/icons/FacebookIcon.svg";
import DownloadAppIllustration from "assets/DownloadAppIllustration.svg";
import PurchaseEsimIllustration from "assets/PurchaseEsimIllustration.svg";
import ScanQRIllustration from "assets/ScanQRIllustration.svg";
import testimonial1 from "assets/testimonial1.svg";
import testimonial2 from "assets/testimonial2.svg";
import testimonial3 from "assets/testimonial3.svg";

export const SOCIAL_ICONS: Array<SOCIAL_ICONSTYPE> = [
  {
    id: 1,
    icon: TwitterLogo,
    link: "https://twitter.com/giantconnectapp?lang=en",
  },
  {
    id: 2,
    icon: LinkedinLogo,
    link: "https://www.linkedin.com/company/giantconnectapp",
  },
  {
    id: 3,
    icon: TelegramLogo,
    link: "https://t.me/giantprotocol",
  },
  {
    id: 4,
    icon: FacebookLogo,
    link: "https://www.facebook.com/giantconnectapp/",
  },
];

export const POPULAR_COUNTRIES: Array<POPULAR_COUNTRIES_TYPE> = [
  {
    id: 1,
    name: "United States",
    flag: "US",
    destination: "USA",
  },
  {
    id: 2,
    name: "India",
    flag: "IN",
    destination: "IND",
  },
  {
    id: 3,
    name: "Albania",
    flag: "AL",
    destination: "ALB",
  },
];

export const HOW_IT_WORKS_DATA: Array<HOW_IT_WORKS_DATATYPE> = [
  {
    id: 1,
    img: PurchaseEsimIllustration,
    text: "Purchase your <br /> eSIM data plan",
  },
  {
    id: 2,
    img: ScanQRIllustration,
    text: "Scan the QR code to <br /> install your eSIM",
  },
  {
    id: 3,
    img: DownloadAppIllustration,
    text: "Download the app to <br /> see your earnings",
  },
];

export const FOOTER_LINKS: Array<FOOTER_LINKS_DATATYPE> = [
  {
    id: 1,
    link: "https://giantconnect.com/",
    text: "About",
  },
  {
    id: 2,
    link: "https://giantconnect.com/blog/",
    text: "Blog",
  },
  {
    id: 3,
    link: "https://giantconnect.zendesk.com/hc/en-us/articles/4419826397847",
    text: "Installation Guide",
  },
  {
    id: 4,
    link: "https://giantconnect.zendesk.com/hc/en-us",
    text: "FAQs",
  },
  {
    id: 5,
    link: "https://giantconnect.zendesk.com/hc/en-us/requests/new",
    text: "Contact us",
  },
];

export const TESTIMONIAL_CARD_DATA: Array<TESTIMONIAL_CARD_DATATYPE> = [
  {
    id: 1,
    img: testimonial1,
    description:
      "I travel to dozens of countries for work every year. This makes it super easy to stay connected and doesn't break the bank.",
    name: "Conor O.",
    subname: "Ireland",
  },
  {
    id: 2,
    img: testimonial2,
    description:
      "Love it! My 2 year-old can't live without her iPad. With GIANT Connect I can get on demand cellular data anywhere we travel.",
    name: "Kelli F.",
    subname: "USA",
  },
  {
    id: 3,
    img: testimonial3,
    description:
      "The service is great and the rewards keep me coming back. I love that every time I use GIANT Connect I get paid.",
    name: "Joy S.",
    subname: "Malaysia",
  },
];

export const INSTALLATION_CHIP_DATA_ANDROID: Array<INSTALLATION_CHIP_DATA_DATATYPE> =
  [
    {
      id: 1,
      title: "Google Pixel",
    },
    {
      id: 2,
      title: "Samsung galaxy phones",
    },
    {
      id: 3,
      title: "Huawei P40 and P40 Pro",
    },
    {
      id: 4,
      title: "Oppo Find X3 and X5, Reno 5A",
    },
    {
      id: 5,
      title: "Motorola Razr",
    },
    {
      id: 6,
      title: "Rakuten Big and Big-S",
    },
    {
      id: 7,
      title: "Sony Xperia 10 III Lite",
    },
    {
      id: 8,
      title: "HONOR magic 3 and pro plus",
    },
  ];

export const INSTALLATION_CHIP_DATA_IOS: Array<INSTALLATION_CHIP_DATA_DATATYPE> =
  [
    {
      id: 1,
      title: "iPhone XS,Max",
    },
    {
      id: 2,
      title: "iPhone XR",
    },
    {
      id: 3,
      title: "or later with iOS 12.1",
    },
  ];
