import React, { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import InstallationGuide from "views/instalaltionGuide";
import Shop from "views/shop";

const AppRouters: FC = () => {
  const [prevPath, setPrevPath] = React.useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== prevPath) {
      console.log(location);
      setPrevPath(location.pathname);
      (window as any).analytics.page();
    }
  }, [location.pathname, prevPath]);
  return (
    <Routes>
      <Route path={`/`} element={<Shop />} />
      <Route path={`/gift/:id`} element={<Shop />} />
      <Route path={`/installationguide`} element={<InstallationGuide />} />
    </Routes>
  );
};

export default AppRouters;
