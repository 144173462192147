import * as React from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import FooterLogo from "assets/icons/GiantProtocolFooterLogo.svg";
import FooterLogoGiantConnect from "assets/FooterLogoGiantConnect.svg";
import AppleStoreIcon from "assets/AppStoreIcon.svg";
import PlayStoreIcon from "assets/PlayStoreIcon.svg";

import { S } from "./styled";
import { FOOTER_LINKS, SOCIAL_ICONS } from "utils/constants";
import { FOOTER_LINKS_DATATYPE, SOCIAL_ICONSTYPE } from "utils/types";

export const Footer = () => {
  return (
    <>
      <S.CustomFooter maxWidth={false}>
        <S.FooterContainer container>
          <S.FooterLogoContainer item>
            <img
              style={{ height: "3rem", marginBottom: "1rem" }}
              src={FooterLogoGiantConnect}
              alt=""
            />
            <S.FooterSubText>
              A global connectivity economy that is <br />
              owned and governed by its users.
            </S.FooterSubText>
          </S.FooterLogoContainer>
          <S.FooterLinksContainer item>
            <S.LinksContainer>
              {FOOTER_LINKS.map((link: FOOTER_LINKS_DATATYPE) => {
                return (
                  <S.FooterLinks key={link.id}>
                    <a href={link.link} target="_blank" rel="noreferrer">
                      {link.text}
                    </a>
                  </S.FooterLinks>
                );
              })}
            </S.LinksContainer>
            <S.AppStoreContainer>
              <img
                src={PlayStoreIcon}
                onClick={() =>
                  window.open("https://wific0in.app.link/SCPal1gVbrb")
                }
                style={{ cursor: "pointer" }}
                alt={PlayStoreIcon}
              />
              <img
                src={AppleStoreIcon}
                onClick={() =>
                  window.open("https://wific0in.app.link/SCPal1gVbrb")
                }
                style={{ cursor: "pointer" }}
                alt={AppleStoreIcon}
              />
            </S.AppStoreContainer>
            <S.PolicyContainer>
              <S.FooterLinks>
                <a
                  href="https://giantconnect.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </S.FooterLinks>
              <S.FooterLinks>
                <a
                  href="https://giantconnect.com/terms-of-service/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of service
                </a>
              </S.FooterLinks>
            </S.PolicyContainer>
          </S.FooterLinksContainer>
          <S.SocialMediaContainer item>
            <Grid sx={{ display: "flex" }}>
              {SOCIAL_ICONS.map((icon: SOCIAL_ICONSTYPE) => {
                return (
                  <a
                    href={icon.link.toString()}
                    rel="noreferrer"
                    key={icon.id}
                    target="_blank"
                  >
                    <img
                      src={icon.icon}
                      style={{ marginRight: "1rem", cursor: "pointer" }}
                      alt=""
                    />
                  </a>
                );
              })}
            </Grid>
          </S.SocialMediaContainer>
        </S.FooterContainer>
      </S.CustomFooter>
      <S.CopyrightContainer maxWidth={false}>
        @2022, GIANT Protocol Foundation. All rights reserved
      </S.CopyrightContainer>
    </>
  );
};
