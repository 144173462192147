import { styled } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export namespace S {
  export const CustomFooter = styled(Container)(({ theme }) => ({
    background: theme.palette.primary.main,
    padding: "2rem",
    minHeight: "215px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "start",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0",
    },
    // [theme.breakpoints.down("lg")]: {
    //   justifyContent: "flex-start",
    // },
  }));

  export const FooterLogoContainer = styled(Grid)(({ theme }) => ({
    height: "100%",
    padding: "2rem 0",
    // width: "30%",
    // minWidth: "350px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
    "@media (max-width: 900px)": {
      textAlign: "center",
    },
  }));

  export const FooterContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    // display: "flex",
    // flexWrap: "wrap",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    height: "100%",
    padding: "0 4.3rem",
    maxWidth: "1400px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
    "@media (max-width: 1200px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media (max-width: 900px)": {
      gridTemplateColumns: "1fr",
    },
  }));

  export const CopyrightContainer = styled(Container)(({ theme }) => ({
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    display: "grid",
    placeContent: "center",
    height: "3rem",
    fontWeight: 400,
    fontSize: "0.6563rem",
  }));

  export const FooterSubText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
  }));
  export const FooterLink = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.common.white,
  }));

  export const FooterLinks = styled(Typography)(({ theme }: any) => ({
    textDecoration: "none",
    textAlign: "center",
    a: {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
  }));

  export const FooterHeaderText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: "1.125rem",
    fontWeight: 700,
    marginBottom: "2rem",
  }));

  export const FooterLinksContainer = styled(Grid)(({ theme }) => ({
    // width: "35%",
    minWidth: "450px",
    padding: "1rem 2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      minWidth: "375px",
    },
    "@media (max-width: 900px)": {
      gap: "1rem",
    },
  }));
  export const FooterSubContainer = styled(Grid)(({ theme }) => ({
    padding: "2.5rem 1rem 0 0",
    width: "15%",
    gap: "1rem",
    minWidth: "150px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "33%",
    // },
  }));
  export const LinksContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    // gap: "0.5rem",
    "@media (max-width: 425px)": {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  }));
  export const AppStoreContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
  }));
  export const PolicyContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    justifyContent: "center",
  }));

  export const SocialMediaContainer = styled(Grid)(({ theme }) => ({
    padding: "2.5rem 0 0 0rem",
    // width: "30%",
    // maxWidth: "200px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    //   maxWidth: "100%",
    // },
    "@media (max-width: 900px)": {
      justifyContent: "center",
    },
  }));
}
